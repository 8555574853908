import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CategoryPopularIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className}>
    <path
      d="M7.88187 9.80309C7.88987 9.79801 7.89772 9.7927 7.90542 9.78717C10.0905 8.2185 11.1386 6.94194 11.62 5.83365C11.8786 5.23813 11.9651 4.70176 11.9897 4.23888C13.708 5.72204 14.2144 7.21136 14.2094 8.49751C14.2035 10.0037 13.4965 11.336 12.9278 12.1891C12.8132 12.3609 12.8162 12.5856 12.9351 12.7544C13.0541 12.9233 13.2647 13.0016 13.4651 12.9515C14.2631 12.752 15.3167 12.1565 16.2005 11.53C16.6501 11.2113 17.0734 10.8721 17.417 10.5504C17.5198 10.4541 17.6195 10.3558 17.7127 10.2568C19.4279 13.1479 19.2237 15.433 18.3626 17.0836C17.4185 18.8935 15.6424 20.0245 14.4372 20.3593C12.6861 20.8457 10.9385 20.8331 9.92522 20.3839C4.27362 17.8786 4.48288 11.9617 7.88187 9.80309Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
