'use client';
import React from 'react';

export const BetDividerMobile = () => {
  return (
    <div className="flex h-[0px] w-full items-center justify-between bg-black">
      <div className="ml-[-8px] h-[9px] w-[5px] rounded-r-full bg-black" />
      <div className="h-[1px] w-full border-b border-divider-standard" />
      <div className="mr-[-8px] h-[9px] w-[5px] rounded-l-full bg-black" />
    </div>
  );
};
