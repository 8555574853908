import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const StarIconMobile: React.FC<Props> = ({ className }) => (
  <SvgIcon size={16} className={className} fill="none">
    <path
      d="M8.68283 2.42083L9.93017 5.08972C9.98185 5.20508 10.0617 5.30553 10.1624 5.38181C10.2631 5.45809 10.3814 5.50774 10.5063 5.52617L13.3516 5.96262C13.4906 5.98529 13.6208 6.04566 13.728 6.13716C13.8352 6.22866 13.9153 6.3478 13.9597 6.48168C14.0041 6.61555 14.011 6.75906 13.9798 6.8966C13.9485 7.03415 13.8803 7.16049 13.7824 7.26194L11.6734 9.43416C11.5907 9.52132 11.5292 9.6264 11.4935 9.74125C11.4579 9.85609 11.4492 9.97761 11.468 10.0964L11.969 13.1064C11.9926 13.2487 11.9758 13.3949 11.9203 13.528C11.8648 13.6612 11.773 13.776 11.6553 13.8593C11.5376 13.9426 11.3989 13.9909 11.255 13.9988C11.1111 14.0068 10.9679 13.9739 10.8419 13.904L8.37225 12.5295C8.25848 12.4676 8.13105 12.4351 8.00156 12.4351C7.87207 12.4351 7.74459 12.4676 7.63081 12.5295L5.15618 13.904C5.03011 13.9739 4.88693 14.0068 4.74305 13.9988C4.59918 13.9909 4.46044 13.9426 4.34277 13.8593C4.22509 13.776 4.13323 13.6612 4.07774 13.528C4.02225 13.3949 4.0054 13.2487 4.02906 13.1064L4.53001 10.0964C4.55006 9.9776 4.5419 9.85576 4.50622 9.74072C4.47054 9.62568 4.40833 9.52066 4.3246 9.43416L2.21566 7.26194C2.11779 7.16049 2.04953 7.03415 2.01827 6.8966C1.987 6.75906 1.99393 6.61555 2.03832 6.48168C2.08271 6.3478 2.16288 6.22866 2.27008 6.13716C2.37729 6.04566 2.50743 5.98529 2.64646 5.96262L5.49685 5.52617C5.6213 5.50644 5.73893 5.4563 5.83941 5.38017C5.9399 5.30405 6.02008 5.20428 6.07289 5.08972L7.31521 2.42083C7.37921 2.29425 7.47702 2.1879 7.59773 2.11361C7.71843 2.03933 7.85737 2 7.99905 2C8.14073 2 8.27961 2.03933 8.40032 2.11361C8.52103 2.1879 8.61883 2.29425 8.68283 2.42083Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
  </SvgIcon>
);
