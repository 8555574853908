import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CategoryDropsIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className}>
    <path
      d="M9.71815 14.2724L6.96638 17.0241C6.37979 17.6108 5.51211 17.8156 4.72511 17.5533C3.83077 17.2552 3.22754 16.4182 3.22754 15.4754V14.2724L4.48567 7.98157C4.83239 6.24792 6.3546 5 8.12259 5H16.8772C18.6451 5 20.1673 6.2479 20.5141 7.98154L21.7722 14.2724V15.4754C21.7722 16.4182 21.169 17.2552 20.2747 17.5533C19.4876 17.8156 18.62 17.6108 18.0334 17.0242L15.2816 14.2724H9.71815Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.71826 5L10.6455 6.85447H14.3544L15.2817 5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
