'use client';
import React from 'react';

export const MultiBetDividerMobile = () => {
  return (
    <div className="flex h-[0px] w-full items-center justify-between bg-body">
      <div className="mr-[12px] bg-background-card" />
      <div className="h-[2px] w-full bg-background-card" />
      <div className="ml-[12px] bg-background-card" />
    </div>
  );
};
