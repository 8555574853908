import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const FullScreenIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={20} viewBox="0 0 22 22" fill="none" className={className}>
    <path
      d="M1 1V7.36339"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.36642 1H1"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.36642 7.36339L1 1"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.0002 1H14.6338"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21 7.36339V1"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.6338 7.36339L21.0002 1"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21 21V14.6366"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.6338 20.9992H21.0002"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.6338 14.6366L21.0002 21"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1 20.9992H7.36642"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1 14.6366V21"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.36642 14.6366L1 21"
      stroke="#00C7B1"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </SvgIcon>
);
