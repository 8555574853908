import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  size?: number;
};

export const CaretLeftIcon: React.FC<Props> = ({ className, size = 24 }) => (
  <SvgIcon size={size} className={className} fill="none">
    <path
      d="M10.5 1L1.5 10L10.5 19"
      stroke="currentColor"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);
