import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const ChevronLeft: React.FC<Props> = ({ className }) => (
  <SvgIcon size={16} className={className} fill="none">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 2L5 8L11 14"
        stroke="#00C7B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
