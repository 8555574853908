'use client';

import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import {
  MoveEvent,
  getCoords,
  getMaxOffset,
  getNearest,
  getElementsWidths,
  recalculateOffset,
  applyTransformTranslateX
} from './utils';
import { debounce } from '@/shared/lib/debounce';
import { MutableTouchState, Nullable } from '@/shared/ui/swiping-pane/types';

type Props = {
  containerClassName?: string;
  trackClassName?: string;
  stepperClassName?: string;
  stepperIndicatorClassName?: string;
  children: React.ReactNode;
  leftArrow?: React.ReactNode;
  rightArrow?: React.ReactNode;
  noSwipe?: boolean;
  gap?: number;
  power?: number;
  withStepper?: boolean;
  fadingStepper?: boolean;
  stepperColor?: string;
  arrowsAlwaysVisible?: boolean;
  initialIndex?: number;
  followTouch?: boolean;
};

const DEFAULT_TRANSITION = 'transform 400ms ease-in-out';

export const SwipingPane: React.FC<Props> = ({
  children,
  containerClassName,
  trackClassName,
  stepperClassName,
  stepperIndicatorClassName,
  leftArrow = null,
  rightArrow = null,
  noSwipe,
  gap = 0,
  power = 1,
  withStepper,
  fadingStepper = false,
  arrowsAlwaysVisible = false,
  initialIndex = 0,
  followTouch = false
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const trackRef = useRef<HTMLDivElement | null>(null);
  const childrenRef = useRef<Nullable<HTMLElement>[]>([]);
  const initRef = useRef<boolean>(true);
  const { current: touchState } = useRef<MutableTouchState>({
    previous: 0,
    start: null,
    offset: 0,
    swiped: false
  });

  const [containerHasEnoughSpace, setContainerHasEnoughSpace] = useState(false);
  const [maxOffset, setMaxOffset] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(() => Math.max(0, initialIndex - power));
  const [showFadingStepper, setShowFadingStepper] = useState(false);
  const [currentItems, setCurrentItems] = useState<ReactNode>(children);

  const setChildrenRef = (ref: HTMLElement | null, index: number) => {
    if (ref != null) {
      childrenRef.current[index] = ref;
    }
  };

  const hideFadingStepperDebounced = useMemo(
    () =>
      debounce(() => {
        setTimeout(() => {
          setShowFadingStepper(false);
        }, 1000);
      }, 800),
    []
  );

  const appearFadingStepper = (autoHide = true) => {
    if (!fadingStepper) {
      return;
    }
    setShowFadingStepper(true);
    // needs for arrow clicks - after click we hide track. for mobile it should be hidden on handleEnd
    if (autoHide) {
      hideFadingStepperDebounced();
    }
  };

  const handleStart = (event: MoveEvent) => {
    appearFadingStepper(false);
    if (!noSwipe) {
      touchState.start = getCoords(event);
      touchState.previous = touchState.start[0];
      touchState.offset = currentOffset;
    }
  };

  const handleMove = (event: MoveEvent) => {
    if (touchState.start == null) return;

    const [x0, y0] = touchState.start;
    const [x1, y1] = getCoords(event);
    const dx = x0 - x1;
    const dy = y0 - y1;

    if (Math.abs(dy) > Math.abs(dx)) return;

    touchState.swiped = true;
    applySwipeTransform(x1);
  };

  const handleEnd = () => {
    const nearestIndex = getNearest(touchState.offset, getElementsWidths(childrenRef.current));

    if (touchState.swiped && nearestIndex !== currentIndex) {
      setCurrentIndex(nearestIndex);
    } else if (touchState.swiped && nearestIndex === currentIndex) {
      applyTransformTranslateX(trackRef.current, currentOffset, DEFAULT_TRANSITION);
    }

    touchState.start = null;
    touchState.swiped = false;
    hideFadingStepperDebounced();
  };

  const applySwipeTransform = (xEnd: number) => {
    const track = trackRef.current;

    if (track != null) {
      const dx = xEnd - touchState.previous;
      touchState.previous = xEnd;
      const next = Math.max(0, Math.min(touchState.offset - dx, maxOffset));

      touchState.offset = next;
      applyTransformTranslateX(track, next, followTouch ? '' : DEFAULT_TRANSITION);
    }
  };

  const handlePreviousClick = () => {
    setCurrentIndex(Math.max(0, currentIndex - power));
    appearFadingStepper();
  };

  const handleNextClick = () => {
    setCurrentIndex(Math.min(maxIndex, currentIndex + power));
    appearFadingStepper();
  };

  useEffect(() => {
    if (currentIndex > 0) {
      return;
    }

    setCurrentItems(children);
  }, [children, currentIndex]);

  useEffect(() => {
    const newMaxOffset = initRef.current
      ? getMaxOffset(containerRef.current, trackRef.current)
      : maxOffset;
    const newMaxIndex = getNearest(newMaxOffset, getElementsWidths(childrenRef.current));
    const newIndex = Math.min(newMaxIndex, currentIndex);
    const newOffset = recalculateOffset(newIndex, gap, newMaxOffset, childrenRef.current);
    const transition = initRef.current || newMaxIndex !== maxIndex ? '' : DEFAULT_TRANSITION;

    if (!initRef.current && containerRef.current && trackRef.current) {
      setContainerHasEnoughSpace(containerRef.current.clientWidth >= trackRef.current.scrollWidth);
    }

    setMaxOffset(newMaxOffset);
    setMaxIndex(newMaxIndex);
    setCurrentIndex(newIndex);
    setCurrentOffset(newOffset);
    applyTransformTranslateX(trackRef.current, newOffset, transition);

    initRef.current = false;
  }, [currentIndex, gap, maxOffset, power, maxIndex]);

  useEffect(() => {
    const track = trackRef.current;
    const container = containerRef.current;

    const observer = new ResizeObserver(() => {
      setMaxOffset(getMaxOffset(container, track));
    });

    if (container != null) {
      observer.observe(container);
    }

    childrenRef.current.forEach((el) => {
      if (el != null) {
        observer.observe(el);
      }
    });

    return () => observer.disconnect();
  });

  return (
    <div id="swiping-pane" className="relative min-w-0">
      <div
        ref={containerRef}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
        className={classNames('h-full w-full touch-pan-y overflow-hidden', containerClassName)}
      >
        {!containerHasEnoughSpace &&
          leftArrow &&
          ((currentIndex !== 0 && maxOffset > 0) || arrowsAlwaysVisible) && (
            <div onClick={handlePreviousClick}>{leftArrow}</div>
          )}
        <div ref={trackRef} className={trackClassName}>
          {React.Children.map(currentItems, (child, index) =>
            React.isValidElement(child)
              ? React.cloneElement(child as React.ReactElement<any>, {
                  ref: (ref: HTMLElement) => setChildrenRef(ref, index)
                })
              : child
          )}
        </div>
        {!containerHasEnoughSpace &&
          rightArrow &&
          ((currentIndex !== maxIndex && maxOffset > 0) || arrowsAlwaysVisible) && (
            <div onClick={handleNextClick}>{rightArrow}</div>
          )}
        {withStepper && (
          <div
            className={classNames(
              'absolute bottom-[-9px] left-0 right-0 z-3 block h-4px rounded-sm bg-mint/20',
              {
                'opacity-0 transition-opacity': fadingStepper,
                'opacity-100': fadingStepper && showFadingStepper
              },
              stepperClassName
            )}
          >
            {/* Using the 'style' prop here coz Tailwind does not work correctly with dynamic values */}
            <div
              className={classNames('absolute h-4px rounded-sm bg-mint', stepperIndicatorClassName)}
              style={{
                width: `${100 / (maxIndex + 1)}%`,
                transform: `translateX(${100 * currentIndex}%)`,
                transition: DEFAULT_TRANSITION
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};
