import classNames from 'classnames';
import { ArrowIcon } from '../icons';
import { Button } from '../new-button';

type ArrowProps = {
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  className?: string;
  size?: 'l' | 'm';
  variant?: 'light' | 'secondary';
  dataTestId?: string;
  disabled?: boolean;
};

export const ArrowNavButton: React.FC<ArrowProps> = ({
  onClick,
  className,
  size = 'm',
  variant = 'secondary',
  dataTestId = '',
  disabled
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      size={size}
      variant={variant}
      dataTestId={dataTestId}
      className={classNames(
        {
          'h-[56px] w-[56px] rounded-s': size === 'l',
          'h-[44px] w-[44px] rounded-xs': size === 'm'
        },
        className
      )}
    >
      <ArrowIcon size={size === 'l' ? 24 : 20} strokeWidth={size === 'l' ? 3 : 2} />
    </Button>
  );
};
