'use client';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ArrowIcon } from '../icons';
import { getDataTestId } from '@/shared/lib/get-data-testid';

type Props = {
  title?: string;
  text?: string;
  className?: string;
  withExpand?: boolean;
};

export const SeoBlock: React.FC<Props> = ({ title, text, withExpand = true, className }) => {
  const [showExpander, setShowExpander] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const seoTextTestId = getDataTestId({ nameForTargetElement: 'seoText' });
  const seoTitleTestId = getDataTestId({ nameForTargetElement: 'seoTitle' });

  const handleShowExpander = (show: boolean) => {
    setShowExpander(show);
    setExpanded(false);
  };

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (text && text.length > 200) {
      handleShowExpander(true);
    }
  }, [text]);

  return (
    <div className="relative pb-[60px] md:mb-[-40px]">
      <div
        className={classNames(
          'relative max-h-[1000px] overflow-hidden transition-all',
          { ['max-h-[150px]']: showExpander && !expanded && withExpand },
          className
        )}
      >
        {title && (
          <h1
            data-testid={seoTitleTestId}
            className="text-[20px] font-bold md:text-[28px] md:leading-40"
          >
            {title}
          </h1>
        )}
        {text && (
          <p
            data-testid={seoTextTestId}
            className="mt-2t leading-[18px] text-grey-light md:text-xl md:leading-22"
          >
            {text}
          </p>
        )}
      </div>
      {showExpander && withExpand && (
        <>
          {!expanded && (
            <div className="absolute bottom-[40px] h-[60px] w-full bg-gradient-to-b from-transparent to-main"></div>
          )}

          <div onClick={handleExpandClick}>
            <ArrowIcon
              className={classNames(
                'group absolute -bottom-[10px] left-half -translate-x-half cursor-pointer text-black',
                { ['-rotate-90']: expanded, ['rotate-90']: !expanded }
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};
