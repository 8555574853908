import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  arrowClassName?: string;
};

export const CaretDownIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={12} className={className} fill="none">
    <path
      d="M1.5 3.75L6 8.25L10.5 3.75"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
