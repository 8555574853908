import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CrossIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={20} className={className}>
    <path
      d="M3.75 3.75L17 17M3.75 17L17 3.75"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
