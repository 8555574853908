import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const PromoIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className} fill="none">
    <path
      d="M19.4526 7.25031C19.5808 7.24203 19.7071 7.28458 19.8042 7.36877C19.9013 7.45297 19.9615 7.57205 19.9717 7.70031V10.6403C19.9591 10.7676 19.8982 10.8852 19.8016 10.9689C19.7051 11.0526 19.5802 11.0961 19.4526 11.0903H4.47754C4.35102 11.0959 4.22728 11.0521 4.13232 10.9682C4.03737 10.8843 3.9786 10.7667 3.96839 10.6403V7.70031C3.9722 7.63724 3.98841 7.57553 4.01608 7.51875C4.04376 7.46196 4.08236 7.41121 4.12966 7.3694C4.17697 7.32759 4.23205 7.29555 4.29175 7.27511C4.35144 7.25468 4.41458 7.24625 4.47754 7.25031H19.4526ZM19.4526 6.25031H4.47754C4.0908 6.25023 3.71946 6.40204 3.44321 6.67314C3.16696 6.94423 3.00784 7.31302 3 7.70031V10.6403C3.00389 10.8347 3.04598 11.0264 3.12385 11.2045C3.20172 11.3825 3.31386 11.5435 3.45385 11.6781C3.59384 11.8128 3.75895 11.9185 3.93973 11.9892C4.12052 12.0599 4.31344 12.0943 4.50749 12.0903H19.4825C19.8754 12.0984 20.2554 11.9504 20.5398 11.6787C20.8241 11.4071 20.9895 11.0338 21 10.6403V7.70031C20.9895 7.30687 20.8241 6.93355 20.5398 6.66188C20.2554 6.39021 19.8754 6.24225 19.4825 6.25031H19.4526Z"
      fill="black"
    />
    <path
      d="M18.9737 12.0801V20.5801C18.982 20.682 18.9501 20.7831 18.8847 20.8617C18.8194 20.9402 18.7258 20.9899 18.6243 21.0001H5.33641C5.23664 20.9875 5.14558 20.9368 5.08231 20.8585C5.01904 20.7802 4.98846 20.6804 4.99698 20.5801V12.0801H18.9737ZM19.972 11.0801H3.99864V20.5801C3.99332 20.7613 4.02371 20.9418 4.08807 21.1113C4.15244 21.2808 4.24951 21.4359 4.37375 21.5678C4.49799 21.6996 4.64695 21.8057 4.81213 21.8799C4.97731 21.9541 5.15547 21.9949 5.33641 22.0001H18.6243C18.9906 21.9896 19.3379 21.8344 19.5904 21.5684C19.8429 21.3023 19.9801 20.9471 19.972 20.5801V11.0801Z"
      fill="black"
    />
    <path
      d="M12.9833 12.0801V21.0001H10.9866V12.0801H12.9833ZM13.9816 11.0801H9.98828V22.0001H13.9816V11.0801Z"
      fill="black"
    />
    <path
      d="M14.0516 7.25V11.09H9.90849V7.25H14.0516ZM15.0499 6.25H8.91016V12.09H15.0499V6.25Z"
      fill="black"
    />
    <path
      d="M6.88381 3.00003C7.79262 3.01712 8.6741 3.31428 9.40829 3.85108C10.1425 4.38787 10.6937 5.13823 10.987 6.00003V6.25003H6.88381C6.68523 6.25003 6.49478 6.17102 6.35436 6.03036C6.21394 5.88971 6.13505 5.69895 6.13505 5.50003V3.75003C6.13764 3.55193 6.21736 3.36266 6.35722 3.22257C6.49708 3.08247 6.68603 3.00262 6.88381 3.00003ZM6.88381 2.00003C6.42045 2.00003 5.97607 2.18441 5.64843 2.5126C5.32079 2.84079 5.13672 3.28591 5.13672 3.75003V5.50003C5.13672 5.96416 5.32079 6.40928 5.64843 6.73747C5.97607 7.06566 6.42045 7.25003 6.88381 7.25003H11.9853V6.00003C11.7072 4.85619 11.0526 3.83948 10.1271 3.11381C9.20162 2.38815 8.05912 1.9958 6.88381 2.00003Z"
      fill="black"
    />
    <path
      d="M17.0759 3.00003C17.2737 3.00262 17.4626 3.08247 17.6025 3.22256C17.7423 3.36266 17.8221 3.55192 17.8246 3.75003V5.50003C17.8246 5.69894 17.7457 5.8897 17.6053 6.03036C17.4649 6.17101 17.2745 6.25003 17.0759 6.25003H12.9827V6.00003C13.2716 5.13744 13.8203 4.38577 14.5534 3.84851C15.2864 3.31125 16.1677 3.01482 17.0759 3.00003ZM17.0759 2.00003C15.9018 1.99627 14.7608 2.38899 13.837 3.11476C12.9131 3.84053 12.2605 4.85699 11.9844 6.00003V7.25003H17.0759C17.5392 7.25003 17.9836 7.06565 18.3113 6.73746C18.6389 6.40928 18.823 5.96416 18.823 5.50003V3.75003C18.823 3.2859 18.6389 2.84078 18.3113 2.51259C17.9836 2.1844 17.5392 2.00003 17.0759 2.00003Z"
      fill="black"
    />
  </SvgIcon>
);
