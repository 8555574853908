import React from 'react';

type DividerProps = {
  color?: string;
  className?: string;
};

export const Divider: React.FC<DividerProps> = ({ color = 'bg-main-light', className = '' }) => {
  return <div className={`my-1 w-full p-1px ${color} ${className}`} />;
};
