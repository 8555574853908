import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const ChevronLeftMobile: React.FC<Props> = ({ className }) => (
  <SvgIcon size={12} className={className} fill="none">
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25 1.5L3.75 6L8.25 10.5"
        stroke="#00C7B1"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
