import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
  size?: number;
};

export const UserIcon: React.FC<Props> = ({ className, size = 24 }) => (
  <SvgIcon className={className} width={size} height={size} fill="none" viewBox="0 0 56 56">
    <path
      d="M27.8204 29.9869C36.0977 29.9869 42.8078 23.2741 42.8078 14.9935C42.8078 6.7128 36.0977 0 27.8204 0C19.5431 0 12.833 6.7128 12.833 14.9935C12.833 23.2741 19.5431 29.9869 27.8204 29.9869Z"
      fill="currentColor"
    />
    <path
      d="M50.8286 39.0213C48.0169 35.7807 44.2431 33.0111 40.5755 30.7938C40.084 30.4973 39.5183 30.3347 38.9387 30.3234C38.359 30.3121 37.787 30.4524 37.2834 30.7295C34.4557 32.2835 31.2556 33.0976 28.0007 33.0911C24.7453 33.0978 21.5448 32.2836 18.7166 30.7295C18.213 30.4524 17.641 30.3121 17.0613 30.3234C16.4817 30.3347 15.916 30.4973 15.4245 30.7938C11.7501 33.0111 7.98309 35.7794 5.17145 39.0213C0.797492 44.0607 0 49.7875 0 49.7875V52.6476C0.00108198 53.537 0.368328 54.3896 1.02106 55.0182C1.6738 55.6467 2.55864 55.9998 3.4812 55.9998H52.5188C53.4418 55.9998 54.3271 55.6464 54.9799 55.0173C55.6327 54.3882 55.9996 53.5349 56 52.645V49.7849C56 49.7849 55.2025 44.0607 50.8286 39.0213Z"
      fill="currentColor"
    />
  </SvgIcon>
);
