import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const KenoIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className} fill="none">
    <path
      d="M12 3C13.78 3 15.5201 3.52784 17.0001 4.51678C18.4802 5.50571 19.6337 6.91131 20.3149 8.55585C20.9961 10.2004 21.1743 12.01 20.8271 13.7558C20.4798 15.5016 19.6226 17.1053 18.364 18.364C17.1053 19.6226 15.5016 20.4798 13.7558 20.8271C12.01 21.1743 10.2004 20.9961 8.55585 20.3149C6.91132 19.6337 5.50571 18.4802 4.51678 17.0001C3.52785 15.5201 3 13.78 3 12C3 9.61305 3.94822 7.32387 5.63604 5.63604C7.32387 3.94821 9.61306 3 12 3ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.34591 2.76121 8.17317C2.00434 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6726 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2Z"
      fill="black"
    />
    <path
      d="M8.9501 13.4408C8.9103 12.9433 9.00765 12.4444 9.23156 11.9984C9.45547 11.5523 9.79737 11.1762 10.2201 10.9108C9.87378 10.6677 9.59855 10.3365 9.42277 9.9516C9.247 9.56666 9.17704 9.14181 9.22011 8.72083C9.18806 8.3376 9.24014 7.952 9.37268 7.59099C9.50522 7.22998 9.71503 6.90229 9.98743 6.63083C10.2598 6.35938 10.5883 6.15072 10.9497 6.01944C11.3112 5.88817 11.697 5.83744 12.0801 5.87083C12.4476 5.83269 12.819 5.87616 13.1678 5.99813C13.5166 6.12011 13.8341 6.31757 14.0977 6.57643C14.3614 6.8353 14.5646 7.14917 14.6929 7.49565C14.8213 7.84214 14.8715 8.21268 14.8401 8.58083C14.9044 9.0222 14.845 9.47276 14.6685 9.88236C14.4919 10.292 14.2052 10.6445 13.8401 10.9008C14.5001 11.2008 15.1201 11.9008 15.1201 13.4708C15.1619 13.8927 15.1098 14.3185 14.9674 14.7178C14.825 15.1171 14.596 15.4799 14.2967 15.7801C13.9975 16.0803 13.6354 16.3105 13.2366 16.4542C12.8378 16.5978 12.4121 16.6513 11.9901 16.6108C10.0101 16.5708 8.9501 15.4408 8.9501 13.4408ZM13.1601 13.4408C13.1601 12.2208 12.7601 11.7408 11.9801 11.7408C11.2001 11.7408 10.8401 12.2908 10.8401 13.4408C10.8401 14.5908 11.1801 15.1708 12.0101 15.1708C12.8401 15.1708 13.1801 14.4408 13.1801 13.3608L13.1601 13.4408ZM11.0001 8.75083C11.0001 9.75083 11.3101 10.2308 12.0001 10.2308C12.6901 10.2308 13.0001 9.74084 13.0001 8.79084C13.0001 7.84084 12.7101 7.38083 12.0001 7.38083C11.2901 7.38083 11.0001 7.86083 11.0001 8.67083V8.75083Z"
      fill="black"
    />
    <path
      d="M17.4201 16.5703C16.7323 17.3234 15.8951 17.925 14.9619 18.3365C14.0287 18.7481 13.02 18.9606 12.0001 18.9606C10.9802 18.9606 9.97143 18.7481 9.03823 18.3365C8.10503 17.925 7.26785 17.3234 6.58008 16.5703H5.33008C6.09686 17.62 7.10056 18.4739 8.25956 19.0626C9.41856 19.6513 10.7001 19.9581 12.0001 19.9581C13.3 19.9581 14.5816 19.6513 15.7406 19.0626C16.8996 18.4739 17.9033 17.62 18.6701 16.5703H17.4201Z"
      fill="black"
    />
  </SvgIcon>
);
