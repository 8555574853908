import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CrossCircleIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={16} className={className}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_786_84947)">
        <path
          d="M10.6668 5.33325L8.00016 7.99992M8.00016 7.99992L5.3335 10.6666M8.00016 7.99992L5.3335 5.33325M8.00016 7.99992L10.6668 10.6666"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  </SvgIcon>
);
