import React from 'react';
import classnames from 'classnames';
import { usePagination } from '@/shared/lib/hooks/use-pagination';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

type PaginationProps = {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (pageNum: number) => void;
  siblingCount?: number;
  className?: string;
};

export const TablePagination = (props: PaginationProps) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || (paginationRange || []).length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className={classnames('flex', { [className as string]: !!className })}>
      <li onClick={onPrevious} className="betfm-table-page-arrow" data-disabled={currentPage === 1}>
        <ChevronLeftIcon className="h-[30px]" />
      </li>
      {paginationRange.map((pageNumber: number | string, idx: number) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (typeof pageNumber === 'string') {
          return (
            <li className="betfm-table-page-button pointer-events-none" key={`${pageNumber}${idx}`}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className="betfm-table-page-button"
            onClick={() => onPageChange(pageNumber)}
            data-active={pageNumber === currentPage}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className="betfm-table-page-arrow"
        data-disabled={currentPage === lastPage}
        onClick={onNext}
      >
        <ChevronRightIcon className="h-[30px]" />
      </li>
    </ul>
  );
};
