import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const ArrowLeftIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={12} className={className} fill="none">
    <path
      d="M8.25 1.5L3.75 6L8.25 10.5"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
