import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
};

export const DoneRoundIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon width={16} height={16} viewBox="0 0 16 16" fill="none" className={className}>
    <rect width="16" height="16" rx="4" fill="#00B147" />
    <path
      d="M2.14142 8.34455C2.06332 8.26644 2.06332 8.13981 2.14142 8.0617L3.36707 6.83605C3.44518 6.75795 3.57181 6.75795 3.64992 6.83605L8.15558 11.3417L6.64709 12.8502L2.14142 8.34455Z"
      fill="#18191D"
    />
    <path
      d="M12.3351 4.14142C12.4132 4.06332 12.5399 4.06332 12.618 4.14142L13.8436 5.36707C13.9217 5.44518 13.9217 5.57181 13.8436 5.64992L6.64168 12.8519L5.13319 11.3434L12.3351 4.14142Z"
      fill="#18191D"
    />
  </SvgIcon>
);
