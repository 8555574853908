'use client';

import { useState } from 'react';

type Props = {
  text: string;
  children: React.ReactNode;
  key: string;
  className?: string;
  open?: boolean;
};

export const ExpandableInput: React.FC<Props> = ({ text, children, key, className, open }) => {
  const [isExpanded, setExpanded] = useState(!!open);
  const toggleExpanded = () => setExpanded(true);
  return (
    <div className={className}>
      {!isExpanded && (
        <button
          className="text-xl text-primary underline decoration-primary decoration-dotted"
          key={key}
          onClick={toggleExpanded}
        >
          {text}
        </button>
      )}
      {isExpanded && children}
    </div>
  );
};
