import classNames from 'classnames';
import React from 'react';
import { CopyIcon } from '../icons/copy';

type CopyToClipboardProps = {
  content: string;
  className?: string;
  children?: React.ReactNode;
};

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  content,
  children,
  className
}) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      console.log('Copied to clipboard:', content);
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
    }
  };

  return (
    <div onClick={handleCopy} className={classNames('flex cursor-pointer items-center', className)}>
      {children}
      <CopyIcon className="ml-xxxs" />
    </div>
  );
};
