import { SvgIcon } from './svg-icon';

export const VKIcon = () => (
  <SvgIcon size={50}>
    <rect
      fill="none"
      opacity="0.2"
      x="0.5"
      y="0.5"
      width="49"
      height="49"
      rx="4.5"
      stroke="currentColor"
    />
    <path d="M26.1523 32.984C17.2747 32.984 12.211 26.99 12 17H16.4469C16.593 24.3207 19.8714 27.3896 22.4682 28.0609V17.016H26.6554V23.3297C29.2197 23.0579 31.9139 20.1808 32.8227 17.016H37.01C36.6735 18.6617 35.9952 20.2212 35.0177 21.5966C34.0402 22.9719 32.7845 24.1333 31.3296 25.008C32.9517 25.8003 34.3848 26.9223 35.5346 28.2998C36.6844 29.6774 37.5246 31.2793 38 33H33.3745C32.9502 31.5026 32.086 30.1621 30.8905 29.1469C29.6951 28.1316 28.2217 27.4869 26.6554 27.2937V33L26.1523 32.984Z" />
  </SvgIcon>
);
