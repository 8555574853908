import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
};

export const FireIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon className={className} fill="none" width={11} height={14}>
    <path
      d="M3.65666 13.5C3.6286 13.4912 3.60105 13.4811 3.57411 13.4697C2.42867 12.9325 1.62256 12.1131 1.17062 10.9989C0.855632 10.2225 0.813786 9.42349 0.94465 8.6108C1.10595 7.60706 1.6043 6.75813 2.37503 6.05097C2.83153 5.63337 3.32151 5.24883 3.80578 4.85902C4.29006 4.4692 4.76748 4.08854 5.15133 3.61358C5.69304 2.94513 5.91977 2.19083 5.84179 1.36195C5.81896 1.11779 5.7676 0.875741 5.72766 0.632635C5.72195 0.597454 5.71473 0.560161 5.70407 0.5C5.7775 0.541163 5.83152 0.567901 5.88211 0.599916C6.65208 1.09246 7.22956 1.73699 7.65905 2.49973C8.14484 3.36309 8.37956 4.28872 8.43967 5.25587C8.49711 6.17411 8.39554 7.08004 8.1943 7.97823C8.18783 8.00673 8.18327 8.03593 8.17984 8.06513C8.18368 8.08221 8.18955 8.09885 8.19734 8.11473C8.35255 8.01165 8.50929 7.9244 8.64471 7.81463C9.12975 7.4206 9.40555 6.91609 9.51968 6.33313C9.60718 5.88421 9.58739 5.43529 9.51093 4.98708C9.50345 4.95669 9.50665 4.92489 9.52006 4.89631C9.5581 4.97688 9.59919 5.05744 9.63761 5.13836C9.96269 5.81554 10.2175 6.51976 10.3984 7.24152C10.6005 8.07287 10.694 8.9123 10.5674 9.76124C10.3212 11.4116 9.4261 12.65 7.81997 13.4307C7.79486 13.4427 7.76823 13.4529 7.74388 13.4627C7.7397 13.4645 7.73285 13.4602 7.71611 13.4553C7.73894 13.4201 7.75948 13.385 7.78192 13.3529C8.09881 12.8956 8.20305 12.3946 8.14979 11.8584C8.09273 11.2871 7.83176 10.8047 7.41748 10.3868C7.16298 10.1299 6.9043 9.87664 6.65664 9.61277C6.08868 9.008 5.81972 8.29311 5.7657 7.50187C5.75771 7.37908 5.75315 7.2556 5.74668 7.13351C5.74668 7.12824 5.74136 7.12331 5.72994 7.10361C5.6687 7.13633 5.60517 7.16588 5.5462 7.20177C4.1105 8.07322 3.21956 9.27045 3.00462 10.8656C2.93462 11.3852 2.93995 11.9066 3.06359 12.4206C3.15374 12.796 3.34928 13.1243 3.61557 13.4198C3.63459 13.4409 3.65361 13.4634 3.66997 13.4852L3.65666 13.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
