import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const MiniScreenIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={40} viewBox="0 0 40 40" fill="none" className={className}>
    <rect width="40" height="40" rx="4" />
    <rect x="8.5" y="8.5" width="23" height="23" rx="1.5" stroke="currentColor" />
    <rect x="18.5" y="21.5" width="10" height="7" rx="0.5" stroke="currentColor" />
  </SvgIcon>
);
