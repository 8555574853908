import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const ArchiveIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className} fill="none">
    <path
      d="M0.6 3.41998V3.41996C0.599971 2.67633 0.893694 1.96279 1.41722 1.43463C1.94015 0.907073 2.65007 0.607174 3.39277 0.6H20.55C21.2979 0.6 22.0151 0.897083 22.544 1.42594C23.0729 1.9548 23.37 2.67208 23.37 3.41998V22.8871L18.2198 19.4988L18.0697 19.4H17.89H3.41057C2.66453 19.3975 1.94984 19.0995 1.42305 18.5711C0.89598 18.0425 0.599997 17.3265 0.6 16.58V3.41998Z"
      stroke="#00C7B1"
      strokeWidth="1.2"
    />
    <path d="M5 6H19" stroke="#00C7B1" strokeWidth="1.2" />
    <path d="M5 9H19" stroke="#00C7B1" strokeWidth="1.2" />
    <path d="M5 12H15" stroke="#00C7B1" strokeWidth="1.2" />
  </SvgIcon>
);
