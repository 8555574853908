import React from 'react';

type Props = {
  left?: React.JSX.Element;
  right?: React.JSX.Element;
  children: React.ReactNode;
};

export const ContentWithColumns: React.FC<Props> = ({ left, right, children }) => (
  <div className="mt-4t flex gap-2t">
    {left}
    <div className="grow overflow-hidden">{children}</div>
    {right}
  </div>
);
