import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  size?: number;
};

export const WalletIcon: React.FC<Props> = ({ className, size = 29 }) => (
  <SvgIcon size={size} className={className} fill="none" viewBox="0 0 20 20">
    <path
      d="M7.0608 5.70348L6.68665 5.05347L10.6452 2.76061C10.8647 2.63259 11.1074 2.54958 11.3592 2.51639C11.6109 2.48321 11.8668 2.50051 12.1118 2.56729C12.3573 2.63248 12.5874 2.74589 12.7889 2.90096C12.9903 3.05604 13.1591 3.24968 13.2854 3.47067L14.2757 5.1911L13.6278 5.56647L12.6375 3.84604C12.5603 3.71052 12.4569 3.59178 12.3335 3.49672C12.21 3.40167 12.069 3.3322 11.9185 3.29238C11.7683 3.25142 11.6114 3.24083 11.457 3.26124C11.3027 3.28165 11.1539 3.33264 11.0193 3.41124L7.0608 5.70348Z"
      fill="currentColor"
    />
    <path
      d="M15.9409 9.526C16.0474 9.526 16.1528 9.54703 16.2511 9.58791C16.3495 9.62878 16.4389 9.68869 16.5141 9.76421C16.5894 9.83973 16.6491 9.92938 16.6899 10.0281C16.7306 10.1267 16.7516 10.2325 16.7516 10.3393V12.2161C16.7516 12.4318 16.6662 12.6387 16.5141 12.7912C16.3621 12.9437 16.1559 13.0294 15.9409 13.0294H12.823C12.608 13.0294 12.4018 12.9437 12.2498 12.7912C12.0977 12.6387 12.0123 12.4318 12.0123 12.2161V10.3393C12.0123 10.2325 12.0333 10.1267 12.0741 10.0281C12.1148 9.92938 12.1745 9.83973 12.2498 9.76421C12.3251 9.68869 12.4144 9.62878 12.5128 9.58791C12.6111 9.54703 12.7165 9.526 12.823 9.526H15.9409ZM15.9409 8.77527H12.823C12.4095 8.77527 12.013 8.94005 11.7207 9.23336C11.4283 9.52667 11.264 9.92449 11.264 10.3393V12.2161C11.264 12.6309 11.4283 13.0287 11.7207 13.3221C12.013 13.6154 12.4095 13.7802 12.823 13.7802H15.9409C16.3544 13.7802 16.7509 13.6154 17.0433 13.3221C17.3356 13.0287 17.4999 12.6309 17.4999 12.2161V10.3393C17.4999 9.92449 17.3356 9.52667 17.0433 9.23336C16.7509 8.94005 16.3544 8.77527 15.9409 8.77527Z"
      fill="currentColor"
    />
    <path
      d="M13.5127 10.5544C13.3488 10.5546 13.1916 10.6201 13.0757 10.7364C12.9599 10.8528 12.8948 11.0106 12.8948 11.1751C12.8948 11.3395 12.9599 11.4972 13.0758 11.6134C13.1917 11.7297 13.3488 11.795 13.5127 11.795C13.6767 11.795 13.8339 11.7297 13.9499 11.6135C14.0659 11.4972 14.1312 11.3395 14.1313 11.1751C14.1313 11.0105 14.0662 10.8526 13.9502 10.7362C13.8342 10.6198 13.6768 10.5544 13.5127 10.5544Z"
      fill="currentColor"
    />
    <path
      d="M15.4704 13.0294V15.936C15.4704 16.1517 15.385 16.3586 15.233 16.5111C15.081 16.6636 14.8748 16.7493 14.6598 16.7493H4.05884C3.84384 16.7493 3.63765 16.6636 3.48562 16.5111C3.33359 16.3586 3.24818 16.1517 3.24818 15.936V6.55182C3.24818 6.33612 3.33359 6.12926 3.48562 5.97674C3.63765 5.82421 3.84384 5.73853 4.05884 5.73853H14.6598C14.8748 5.73853 15.081 5.82421 15.233 5.97674C15.385 6.12926 15.4704 6.33612 15.4704 6.55182V9.52598H16.2187V6.55182C16.2187 6.13702 16.0545 5.7392 15.7621 5.44589C15.4698 5.15258 15.0732 4.98779 14.6598 4.98779H4.05884C3.64538 4.98779 3.24886 5.15258 2.9565 5.44589C2.66414 5.7392 2.49988 6.13702 2.49988 6.55182V15.936C2.49988 16.3508 2.66414 16.7486 2.9565 17.0419C3.24886 17.3352 3.64538 17.5 4.05884 17.5H14.6598C15.0732 17.5 15.4698 17.3352 15.7621 17.0419C16.0545 16.7486 16.2187 16.3508 16.2187 15.936V13.0294H15.4704Z"
      fill="currentColor"
    />
  </SvgIcon>
);
