import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const FastGamesIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className} fill="none">
    <path
      d="M12 3C13.78 3 15.5201 3.52784 17.0001 4.51678C18.4802 5.50571 19.6337 6.91131 20.3149 8.55585C20.9961 10.2004 21.1743 12.01 20.8271 13.7558C20.4798 15.5016 19.6226 17.1053 18.364 18.364C17.1053 19.6226 15.5017 20.4798 13.7558 20.8271C12.01 21.1743 10.2004 20.9961 8.55586 20.3149C6.91132 19.6337 5.50571 18.4802 4.51678 17.0001C3.52785 15.5201 3.00001 13.78 3.00001 12C3.00001 9.61305 3.94822 7.32387 5.63604 5.63604C7.32387 3.94821 9.61306 3 12 3ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.34591 2.76121 8.17317C2.00434 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6726 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2Z"
      fill="black"
    />
    <path
      d="M15.9303 9.82977H12.5503L15.7203 4.75977H11.7003L8.16031 12.2798H10.9303L8.07031 19.2398L15.9303 9.82977Z"
      fill="black"
    />
  </SvgIcon>
);
