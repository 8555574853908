import { SvgIcon } from './svg-icon';
import { FC } from 'react';

type Props = {
  className?: string;
  mode?: 'light' | 'dark';
};

export const CheckIcon: FC<Props> = ({ className, mode = 'dark' }) => {
  const fill = mode === 'dark' ? '#f6f7f9' : '#000000';
  return (
    <SvgIcon width={13} height={12} className={className} viewBox="0 0 13 12" fill="none">
      <path
        d="M1.75 6L5.5 9.75L11.75 2.25"
        stroke={fill}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
