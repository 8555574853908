import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CopyIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon width={16} height={16} className={className} fill="none">
    <path
      d="M5.33301 2.66668V10.6667C5.33301 11.4031 5.92996 12 6.66634 12H11.9997C12.7361 12 13.333 11.4031 13.333 10.6667V4.82776C13.333 4.46894 13.1884 4.12527 12.9318 3.87442L10.7216 1.71334C10.4725 1.46974 10.1379 1.33334 9.78947 1.33334H6.66634C5.92996 1.33334 5.33301 1.9303 5.33301 2.66668Z"
      stroke="#00C7B1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.667 12V13.3333C10.667 14.0697 10.0701 14.6667 9.33366 14.6667H4.00033C3.26395 14.6667 2.66699 14.0697 2.66699 13.3333V5.99999C2.66699 5.26361 3.26395 4.66666 4.00033 4.66666H5.33366"
      stroke="#00C7B1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
