import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const LaunchIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon width={24} height={18} className={className}>
    <path
      d="M21.0985 0H5.51844L4.09872 4.36417H14.9939L0.957872 13.8114C0.720668 13.9719 0.517453 14.1774 0.359823 14.4163C0.202193 14.6552 0.093239 14.9227 0.0391813 15.2036C-0.0148764 15.4845 -0.0129802 15.7733 0.0447638 16.0535C0.102508 16.3336 0.214966 16.5997 0.375721 16.8365C0.536476 17.0733 0.742378 17.2761 0.98167 17.4335C1.22096 17.5908 1.48896 17.6996 1.77036 17.7536C2.05176 17.8075 2.34105 17.8056 2.62172 17.748C2.90238 17.6903 3.16893 17.5781 3.40613 17.4176L17.5549 7.88536L14.4486 17.4337H19.421L23.8573 3.80142C23.9992 3.36619 24.0359 2.90366 23.9646 2.45156C23.8932 1.99946 23.7158 1.5706 23.4469 1.19997C23.1779 0.829341 22.8249 0.527442 22.4168 0.318901C22.0087 0.110361 21.5569 0.00108749 21.0985 0Z"
      fill="currentColor"
    />
  </SvgIcon>
);
