import React from 'react';
import classNames from 'classnames';

type Props = {
  color?: 'light' | 'dark';
  className?: string;
  children?: React.ReactNode;
};

export const Skeleton: React.FC<Props> = ({ className, color = 'light', children }) => (
  <div
    className={classNames(
      'animate-pulse',
      color === 'light' ? 'bg-main-light' : 'bg-main',
      className
    )}
  >
    {children}
  </div>
);
