import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CategoryLiveIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className} fill="none">
    <g clip-path="url(#clip0_10059_6666)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.80093 9.98805H5.2666C4.16203 9.98805 3.2666 10.8835 3.2666 11.9881V19.8479C3.2666 20.9525 4.16203 21.8479 5.2666 21.8479H13.1265C14.231 21.8479 15.1265 20.9525 15.1265 19.8479V14.4667L14.1265 14.7346V19.8479C14.1265 20.4002 13.6787 20.8479 13.1265 20.8479H5.2666C4.71432 20.8479 4.2666 20.4002 4.2666 19.8479V11.9881C4.2666 11.4358 4.71432 10.9881 5.2666 10.9881H10.0689L9.80093 9.98805Z"
        fill="currentColor"
      />
      <path
        d="M5.98291 13.1448C5.98291 12.9791 6.11722 12.8448 6.28291 12.8448H7.66024C7.82592 12.8448 7.96024 12.9791 7.96024 13.1448V14.5221C7.96024 14.6878 7.82592 14.8221 7.66024 14.8221H6.28291C6.11722 14.8221 5.98291 14.6878 5.98291 14.5221V13.1448Z"
        fill="currentColor"
      />
      <rect
        x="8.82038"
        y="4.39588"
        width="10.8599"
        height="10.8599"
        rx="1.5"
        transform="rotate(-15 8.82038 4.39588)"
        stroke="currentColor"
      />
      <path
        d="M11.5654 6.08662C11.5226 5.92658 11.6175 5.76208 11.7776 5.7192L13.108 5.36272C13.268 5.31984 13.4325 5.41481 13.4754 5.57485L13.8319 6.90525C13.8747 7.06529 13.7798 7.22979 13.6197 7.27267L12.2893 7.62915C12.1293 7.67203 11.9648 7.57706 11.9219 7.41702L11.5654 6.08662Z"
        fill="currentColor"
      />
      <path
        d="M5.98291 17.3644C5.98291 17.1987 6.11722 17.0644 6.28291 17.0644H7.66024C7.82592 17.0644 7.96024 17.1987 7.96024 17.3644V18.7417C7.96024 18.9074 7.82592 19.0417 7.66024 19.0417H6.28291C6.11722 19.0417 5.98291 18.9074 5.98291 18.7417V17.3644Z"
        fill="currentColor"
      />
      <path
        d="M10.2539 17.3644C10.2539 17.1987 10.3882 17.0644 10.5539 17.0644H11.9312C12.0969 17.0644 12.2312 17.1987 12.2312 17.3644V18.7417C12.2312 18.9074 12.0969 19.0417 11.9312 19.0417H10.5539C10.3882 19.0417 10.2539 18.9074 10.2539 18.7417V17.3644Z"
        fill="currentColor"
      />
      <path
        d="M17.1519 9.41221C17.109 9.25217 17.204 9.08767 17.364 9.04479L18.6944 8.68831C18.8544 8.64543 19.0189 8.7404 19.0618 8.90044L19.4183 10.2308C19.4612 10.3909 19.3662 10.5554 19.2062 10.5983L17.8758 10.9547C17.7157 10.9976 17.5512 10.9026 17.5083 10.7426L17.1519 9.41221Z"
        fill="currentColor"
      />
      <path
        d="M14.5161 7.50227C14.4732 7.34223 14.5682 7.17773 14.7283 7.13485L16.0586 6.77837C16.2187 6.73549 16.3832 6.83046 16.4261 6.9905L16.7826 8.3209C16.8254 8.48094 16.7305 8.64544 16.5704 8.68832L15.24 9.0448C15.08 9.08768 14.9155 8.99271 14.8726 8.83267L14.5161 7.50227Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_10059_6666">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
