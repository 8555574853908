import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  size?: number;
};

export const LockIcon: React.FC<Props> = ({ className, size = 29 }) => (
  <SvgIcon size={size} className={className} viewBox="0 0 20 20" fill="none">
    <path
      d="M4.26237 6.22122H15.7365C16.3156 6.22122 16.8989 6.77098 16.8989 7.5979V15.5186C16.8989 16.3456 16.3156 16.8953 15.7365 16.8953H4.26237C3.6833 16.8953 3.1 16.3456 3.1 15.5186V7.5979C3.1 6.77098 3.6833 6.22122 4.26237 6.22122Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
    <path
      d="M13.644 5.93373V4.28423C13.644 3.90964 13.4953 3.55037 13.2304 3.2855C12.9655 3.02062 12.6062 2.87183 12.2316 2.87183H7.76944C7.39485 2.87183 7.03563 3.02062 6.77075 3.2855C6.50588 3.55037 6.35712 3.90964 6.35712 4.28423V5.93373"
      stroke="currentColor"
      strokeWidth="1.2"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0001 12.1585C10.8216 12.1585 11.4875 11.4926 11.4875 10.6711C11.4875 9.84964 10.8216 9.18372 10.0001 9.18372C9.17868 9.18372 8.51276 9.84964 8.51276 10.6711C8.51276 11.4926 9.17868 12.1585 10.0001 12.1585Z"
      fill="currentColor"
    />
    <path
      d="M10.6372 11.6399C10.6372 11.2878 10.3518 11.0024 9.99976 11.0024C9.6477 11.0024 9.3623 11.2878 9.3623 11.6399V14.0772C9.3623 14.4293 9.6477 14.7147 9.99976 14.7147C10.3518 14.7147 10.6372 14.4293 10.6372 14.0772V11.6399Z"
      fill="currentColor"
    />
  </SvgIcon>
);
