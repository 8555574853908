import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const SupportIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className}>
    <path
      d="M20 6.0072C20.2652 6.0072 20.5196 6.1125 20.7071 6.29992C20.8946 6.48734 21 6.74154 21 7.00659V17.0005C21 17.2655 20.8946 17.5197 20.7071 17.7072C20.5196 17.8946 20.2652 17.9999 20 17.9999H4C3.73478 17.9999 3.48043 17.8946 3.29289 17.7072C3.10536 17.5197 3 17.2655 3 17.0005V7.00659C3 6.74154 3.10536 6.48734 3.29289 6.29992C3.48043 6.1125 3.73478 6.0072 4 6.0072H20ZM20 5.00781H4C3.46957 5.00781 2.96086 5.2184 2.58578 5.59324C2.21071 5.96809 2 6.47648 2 7.00659V17.0005C2 17.5306 2.21071 18.039 2.58578 18.4138C2.96086 18.7887 3.46957 18.9993 4 18.9993H20C20.5304 18.9993 21.0391 18.7887 21.4142 18.4138C21.7893 18.039 22 17.5306 22 17.0005V7.00659C22 6.47648 21.7893 5.96809 21.4142 5.59324C21.0391 5.2184 20.5304 5.00781 20 5.00781Z"
      fill="black"
    />
    <path
      d="M19.5702 6.00786C19.8465 5.99415 20.1211 6.05921 20.3619 6.19545C20.6027 6.33169 20.7997 6.53351 20.9302 6.77739C21.0268 6.95866 21.0652 7.16528 21.0402 7.36913C21.0151 7.57299 20.9278 7.76418 20.7902 7.9167L13.2602 17.4509C13.1072 17.6234 12.9194 17.7615 12.7091 17.8561C12.4988 17.9507 12.2708 17.9996 12.0402 17.9996C11.8095 17.9996 11.5816 17.9507 11.3713 17.8561C11.1609 17.7615 10.9731 17.6234 10.8202 17.4509L3.29017 7.9167C3.15252 7.76418 3.06523 7.57299 3.04018 7.36913C3.01513 7.16528 3.05354 6.95866 3.15018 6.77739C3.2806 6.53351 3.47769 6.33169 3.71847 6.19545C3.95926 6.05921 4.23382 5.99415 4.51018 6.00786H19.5702ZM19.5702 5.00847H4.51018C4.08425 4.97125 3.6566 5.05719 3.27817 5.25604C2.89973 5.45489 2.58648 5.75827 2.37572 6.13003C2.16495 6.5018 2.06557 6.92628 2.08941 7.35291C2.11325 7.77953 2.25931 8.19032 2.51018 8.53632L10.0002 18.0705C10.2471 18.3595 10.5537 18.5915 10.899 18.7506C11.2443 18.9097 11.62 18.992 12.0002 18.992C12.3804 18.992 12.7561 18.9097 13.1013 18.7506C13.4466 18.5915 13.7533 18.3595 14.0002 18.0705L21.5402 8.53632C21.791 8.19032 21.9371 7.77953 21.9609 7.35291C21.9848 6.92628 21.8854 6.5018 21.6746 6.13003C21.4639 5.75827 21.1506 5.45489 20.7722 5.25604C20.3938 5.05719 19.9661 4.97125 19.5402 5.00847H19.5702Z"
      fill="black"
    />
    <path d="M2.96094 18.1801L7.33094 13.8027" stroke="black" />
    <path d="M21.0792 18.1801L16.6992 13.8027" stroke="black" />
  </SvgIcon>
);
