import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const AddIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={20} viewBox="0 0 20 20" fill="none" className={className}>
    <rect
      id="Rectangle 319"
      x="1.00391"
      y="1"
      width="18"
      height="18"
      rx="3"
      stroke="black"
      strokeWidth="2"
    />
    <rect id="Rectangle 317" x="9.00391" y="5" width="2" height="10" fill="black" />
    <rect
      id="Rectangle 318"
      x="15.0039"
      y="9"
      width="2"
      height="10"
      transform="rotate(90 15.0039 9)"
      fill="black"
    />
  </SvgIcon>
);
