import classNames from 'classnames';

type Props = {
  className?: string;
  arrowClassName?: string;
  size?: number;
  strokeWidth?: number;
};

export const ArrowIcon: React.FC<Props> = ({
  className,
  size = 24,
  strokeWidth = 3,
  arrowClassName
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.77344 3L15.7734 12L6.77344 21"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames('text-currentColor', arrowClassName)}
    />
  </svg>
);
