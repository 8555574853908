'use client';

import React, { ChangeEvent, forwardRef, useState } from 'react';
import classNames from 'classnames';
import InputMask from '@mona-health/react-input-mask';

import { EyeIcon, CrossIcon } from '../icons';

export type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  errorMessage?: string;
  className?: string;
  innerIcon?: React.ReactNode;
  infoText?: React.ReactNode;
  mask?: string;
  placeholder?: string;
  hideErrorMessage?: boolean;
  bottomContent?: React.ReactNode;
  showNumberArrows?: boolean;
  disabled?: boolean;
  onInputStart?: () => void;
  currency?: string;
  leftIcon?: React.ReactNode;
  inputClassName?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  hideCross?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

// Приводим InputMask к типу функционального компонента
const MaskedInput = InputMask as unknown as React.FC<any>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      hideCross,
      hideErrorMessage,
      name,
      label,
      innerIcon,
      type = 'text',
      errorMessage,
      infoText,
      className,
      mask,
      onBlur,
      value,
      onChange,
      onInputStart,
      onFocus,
      onKeyDown,
      bottomContent,
      showNumberArrows = false,
      inputMode,
      autoComplete,
      disabled,
      placeholder,
      currency,
      leftIcon,
      inputClassName,
      autoFocus
    },
    ref
  ) => {
    const [passwordVisible, setPasswordVisibility] = useState(false);
    const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setPasswordVisibility((prev) => !prev);
    };
    const [hasStartedInput, setHasStartedInput] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!hasStartedInput && onInputStart) {
        setHasStartedInput(true);
        onInputStart();
      }
      onChange?.(e);
    };

    const [inputFocused, setInputFocused] = useState(false);
    const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setInputFocused(true);
      onFocus?.(e);
    };
    const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setInputFocused(false);
      onBlur?.(e);
    };

    const inputComponent = (
      <div className="relative flex items-center">
        {leftIcon && (
          <div className="absolute inset-y-0 left-0 flex items-center px-3">{leftIcon}</div>
        )}
        {innerIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center px-3">{innerIcon}</div>
        )}
        {value && !hideCross && (
          <div
            className={classNames(
              'absolute inset-y-0 right-0 flex cursor-pointer items-center px-2 md:px-3',
              {
                'mr-4 md:mr-2t': type === 'password'
              }
            )}
            onClick={() => onChange?.({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)}
          >
            <CrossIcon className="h-2 w-2 text-white/70 hover:text-white" />
          </div>
        )}
        <input
          id={id}
          ref={ref}
          name={name}
          type={type === 'password' && passwordVisible ? 'text' : type}
          className={classNames(
            className,
            'border-1 leading-tight h-4t w-full appearance-none rounded-xxs border-divider-standard bg-input pl-[16px] text-sm text-text-primary selection:bg-input placeholder:text-input-placeholder hover:border-divider-light hover:bg-input focus:border-divider-light focus:bg-input focus:outline-none active:bg-input md:h-[54px] md:rounded-s md:text-xl',
            { '!border-divider-light bg-input outline-none': Boolean(value) },
            { '!border-error focus:!border-divider-light': !!errorMessage },
            { 'hide-arrow': type === 'number' && !showNumberArrows },
            inputClassName
          )}
          onBlur={onInputBlur}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          onFocus={onInputFocus}
          inputMode={inputMode}
          autoComplete={autoComplete}
        />
        {value && currency && (
          <div className="pointer-events-none absolute left-2">
            <span ref={ref} className="invisible whitespace-pre text-sm md:text-xl">
              {value || placeholder}
            </span>
            <span className="absolute top-[51%] ml-1 -translate-y-1/2 transform text-sm text-white md:text-xl">
              {currency}
            </span>
          </div>
        )}
      </div>
    );

    if (type === 'hidden') {
      return <div>{inputComponent}</div>;
    }

    return (
      <div
        className={classNames(
          'mb-[4px] min-h-[60px] font-roboto md:mb-[8px] md:min-h-[112px]',
          className
        )}
      >
        {label && (
          <label
            htmlFor={name}
            className="typography-body-regular mb-[8px] block text-text-primary md:typography-title-regular"
          >
            {label}
          </label>
        )}
        <div className={classNames('relative w-full', { 'pointer-events-none': disabled })}>
          {innerIcon && (
            <div className="absolute inset-y-0 right-0 flex items-center px-3">{innerIcon}</div>
          )}
          {type === 'password' && (
            <button
              className="absolute bottom-0 right-0 z-[10] flex h-4t cursor-pointer items-center pr-2 md:h-[54px]"
              onClick={togglePasswordVisibility}
            >
              <EyeIcon crossed={!passwordVisible} className="text-text-disabled" />
            </button>
          )}
          {inputComponent}
        </div>
        {!hideErrorMessage && (
          <div
            className={classNames(
              'mt-[4px] h-2 text-sm md:mt-[8px]',
              errorMessage ? 'text-error' : 'text-transparent'
            )}
          >
            {errorMessage && !inputFocused ? errorMessage : ' '}
          </div>
        )}
        {infoText}
        {bottomContent}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
