import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const TelegramIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={50} className={className}>
    <rect
      fill="none"
      opacity="0.2"
      x="0.5"
      y="0.5"
      width="49"
      height="49"
      rx="4.5"
      stroke="currentColor"
    />
    <path d="M12.6433 23.626C19.0848 20.8125 23.3791 18.9492 25.5262 18.036C31.6548 15.4631 32.9431 15.0189 33.7713 15.0004C34.0795 14.9935 34.382 15.0842 34.6363 15.2595C34.8105 15.4184 34.9216 15.6354 34.9491 15.8703C35.0008 16.1573 35.0132 16.45 34.986 16.7403C34.6547 20.2572 33.2192 28.7903 32.483 32.7145C32.1701 34.3804 31.5628 34.9357 30.9739 34.9912C29.6856 35.1023 28.7101 34.1397 27.4771 33.3253C25.5262 32.0481 24.4404 31.2522 22.5448 29.9935C20.3547 28.5497 21.7718 27.7538 23.0233 26.4581C23.3545 26.1249 29.0046 20.9421 29.115 20.4793C29.1288 20.4104 29.126 20.3391 29.1068 20.2715C29.0876 20.2039 29.0525 20.1418 29.0046 20.0906C28.9363 20.0482 28.859 20.0227 28.779 20.0163C28.699 20.0099 28.6186 20.0226 28.5445 20.0536C28.3421 20.0906 25.2502 22.1637 19.232 26.2545C18.3486 26.8653 17.5572 27.1615 16.8395 27.1429C16.0481 27.1244 14.539 26.6987 13.3979 26.3285C12.0176 25.8843 10.9133 25.6436 11.0054 24.8662C11.0606 24.459 11.6127 24.0518 12.6433 23.626" />
  </SvgIcon>
);
