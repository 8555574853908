type Props = {
  className?: string;
  size?: number;
};

export const FilterIcon: React.FC<Props> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 15 15"
    width={size}
    height={size}
  >
    <path
      fill="#00c7b1"
      d="M13.5,1l-5,5v7.5l-2.1-2.1V5.9L1.5,1H13.5 M14.3,0H0.7C0.4,0,0.2,0.1,0.1,0.4C-0.1,0.7,0,1,0.2,1.2l5.2,5.3v5.2  c0,0.2,0,0.3,0.2,0.5l2.7,2.7C8.4,15,8.7,15,8.9,15c0.1,0,0.2,0,0.3-0.1c0.3-0.2,0.4-0.3,0.4-0.6V6.4c0,0,3.9-4,5.2-5.3  C15,0.9,15,0.7,14.9,0.4C14.7,0.1,14.6,0,14.3,0L14.3,0z"
    />
  </svg>
);
