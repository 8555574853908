import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const BannerClose: React.FC<Props> = ({ className }) => (
  <SvgIcon size={40} className={className}>
    <path d="M1 1L41 41" stroke="white" strokeWidth="2" />
    <path d="M41 1L1 41" stroke="white" strokeWidth="2" />
  </SvgIcon>
);
