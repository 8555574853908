import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  crossed?: boolean;
};

export const EyeIcon: React.FC<Props> = ({ className, crossed }) => (
  <SvgIcon width={20} height={14} className={className}>
    {crossed ? (
      <>
        <path
          d="M9.73 3.06745L11.6213 1.19469C11.09 1.11143 10.55 1.06055 10 1.06055C5.9531 1.06055 2.35 3.38246 0 7.00047C0.739925 8.13873 1.63766 9.16847 2.6669 10.0595L6.0269 6.73317C6.09112 5.78183 6.50189 4.88588 7.18294 4.21171C7.86399 3.53754 8.76903 3.13095 9.73 3.06745Z"
          fill="currentColor"
        />
        <path
          d="M15.7082 2.74939L17.071 1.39994L15.6572 0L2.92969 12.6001L4.34379 14L6.17279 12.1893C7.38339 12.6613 8.66338 12.9404 10.001 12.9404C14.0479 12.9404 17.651 10.6184 20.001 7.0005C18.8448 5.2204 17.3769 3.77373 15.7082 2.74939ZM10.001 10.9604C9.28737 10.9608 8.58715 10.7686 7.97568 10.4044L9.17568 9.21638C9.43358 9.31281 9.70688 9.37686 10.0005 9.37686C11.3259 9.37686 12.4005 8.31302 12.4005 7.00089C12.4005 6.71023 12.3358 6.43967 12.2384 6.18435L13.4384 4.99587C13.8065 5.60102 14.001 6.29408 14.001 7.0005C14.001 9.18787 12.2101 10.9604 10.001 10.9604Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <path
          d="M9.99999 0.98999C5.9531 0.98999 2.35 3.31201 0 6.92992C2.35 10.5478 5.9531 12.8698 9.99999 12.8698C14.0469 12.8698 17.65 10.5478 20 6.92992C17.65 3.31201 14.0469 0.98999 9.99999 0.98999ZM9.99999 10.8899C7.7909 10.8899 6 9.1169 6 6.92992C6 4.74293 7.7909 2.96997 9.99999 2.96997C12.2091 2.96997 14 4.74293 14 6.92992C14 9.1169 12.2091 10.8899 9.99999 10.8899Z"
          fill="currentColor"
        />
        <path
          d="M10.0016 9.3062C11.327 9.3062 12.4016 8.24244 12.4016 6.93023C12.4016 5.61802 11.327 4.55426 10.0016 4.55426C8.67608 4.55426 7.60156 5.61802 7.60156 6.93023C7.60156 8.24244 8.67608 9.3062 10.0016 9.3062Z"
          fill="currentColor"
        />
      </>
    )}
  </SvgIcon>
);
