import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export const PageHeader: React.FC = () => {
  return (
    <div>
      <div>
        <nav className="sm:hidden" aria-label="Back">
          <a
            href="#"
            className="text-gray-500 hover:text-gray-700 flex items-center text-sm font-medium"
          >
            <ChevronLeftIcon
              className="text-gray-400 -ml-1 mr-1 h-5 w-5 flex-shrink-0"
              aria-hidden="true"
            />
            Back
          </a>
        </nav>
        <nav className="sm:flex hidden" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="#" className="text-gray-500 hover:text-gray-700 text-sm font-medium">
                  Jobs
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="text-gray-400 h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <a href="#" className="text-gray-500 hover:text-gray-700 ml-4 text-sm font-medium">
                  Engineering
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="text-gray-400 h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <a
                  href="#"
                  aria-current="page"
                  className="text-gray-500 hover:text-gray-700 ml-4 text-sm font-medium"
                >
                  Back End Developer
                </a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight text-2xl font-bold">
            Back End Developer
          </h2>
        </div>
      </div>
    </div>
  );
};
