import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  size?: number;
  id?: string;
};

export const QuestionIcon: React.FC<Props> = ({ className, size = 24 }) => (
  <SvgIcon size={size} fill="#B2B3B7" className={className}>
    <g clipPath="url(#clip0_4371_6013)">
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H8C3.58172 24 0 20.4183 0 16V8Z"
        fill="inherit"
      />
      <path
        d="M10.8374 14.0071V13.8778C10.8407 13.2779 10.897 12.8006 11.0064 12.446C11.1191 12.0914 11.2782 11.8063 11.4837 11.5909C11.6892 11.3721 11.9394 11.1716 12.2344 10.9893C12.4366 10.8634 12.6172 10.7258 12.7763 10.5767C12.9387 10.4242 13.0663 10.2552 13.1591 10.0696C13.2519 9.88066 13.2983 9.6702 13.2983 9.43819C13.2983 9.17635 13.237 8.94932 13.1143 8.75708C12.9917 8.56485 12.826 8.4157 12.6172 8.30964C12.4117 8.20358 12.1813 8.15055 11.9261 8.15055C11.6908 8.15055 11.4671 8.20192 11.255 8.30467C11.0462 8.4041 10.8722 8.55656 10.733 8.76205C10.5971 8.96423 10.5208 9.2211 10.5043 9.53265H8.73438C8.75095 8.90291 8.90341 8.37592 9.19176 7.95168C9.48343 7.52744 9.8679 7.20926 10.3452 6.99714C10.8258 6.78502 11.3561 6.67896 11.9361 6.67896C12.5691 6.67896 13.1259 6.78999 13.6065 7.01205C14.0904 7.23412 14.4666 7.55064 14.7351 7.96163C15.0069 8.3693 15.1428 8.8532 15.1428 9.41333C15.1428 9.79117 15.0814 10.1292 14.9588 10.4275C14.8395 10.7258 14.6688 10.991 14.4467 11.223C14.2247 11.455 13.9612 11.6621 13.6562 11.8444C13.3878 12.0102 13.1674 12.1825 12.995 12.3615C12.826 12.5405 12.7 12.7509 12.6172 12.9929C12.5376 13.2315 12.4962 13.5265 12.4929 13.8778V14.0071H10.8374ZM11.7024 17.1094C11.4041 17.1094 11.1473 17.0033 10.9318 16.7912C10.7164 16.5791 10.6087 16.3205 10.6087 16.0156C10.6087 15.7173 10.7164 15.4621 10.9318 15.25C11.1473 15.0379 11.4041 14.9318 11.7024 14.9318C11.9974 14.9318 12.2526 15.0379 12.468 15.25C12.6868 15.4621 12.7962 15.7173 12.7962 16.0156C12.7962 16.2178 12.7448 16.4017 12.642 16.5674C12.5426 16.7332 12.41 16.8657 12.2443 16.9652C12.0819 17.0613 11.9013 17.1094 11.7024 17.1094Z"
        fill="#323338"
      />
    </g>
    <defs>
      <clipPath id="clip0_4371_6013">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H8C3.58172 24 0 20.4183 0 16V8Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
