import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
  size?: number;
};

export const UserOutlinedIcon: React.FC<Props> = ({ className, size = 24 }) => (
  <SvgIcon className={className} width={size} height={size} fill="none">
    <path
      d="M13.367 6.51611C13.367 8.40301 11.838 9.93222 9.95247 9.93222C8.06694 9.93222 6.53799 8.40301 6.53799 6.51611C6.53799 4.62922 8.06694 3.1 9.95247 3.1C11.838 3.1 13.367 4.62922 13.367 6.51611Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
    <path
      d="M10.0002 11.9637C10.9721 11.9654 11.9288 11.7223 12.7756 11.257L12.7759 11.2568C12.8179 11.2337 12.8672 11.2212 12.9183 11.2222C12.9694 11.2232 13.0179 11.2376 13.0585 11.2621C14.0141 11.8399 14.9661 12.5438 15.6616 13.3454L15.6617 13.3455C16.1866 13.9503 16.5004 14.6008 16.6826 15.1041C16.7733 15.3547 16.8302 15.5654 16.8639 15.7105C16.8808 15.7829 16.8918 15.8387 16.8983 15.8744C16.8989 15.8778 16.8995 15.881 16.9 15.884V16.6011C16.9 16.6732 16.8705 16.7469 16.8104 16.8048C16.7496 16.8634 16.6626 16.9 16.5675 16.9H3.43247C3.33742 16.9 3.25048 16.8634 3.18968 16.8049C3.12969 16.7471 3.10015 16.6735 3.1 16.6016V15.8847C3.10054 15.8817 3.10112 15.8784 3.10173 15.8751C3.10826 15.8393 3.11926 15.7835 3.1361 15.7111C3.16985 15.5659 3.22672 15.3551 3.31745 15.1044C3.49967 14.6009 3.81341 13.9502 4.33833 13.3455L4.33849 13.3453C5.03412 12.5432 5.98453 11.8396 6.94154 11.2621C6.9821 11.2376 7.03059 11.2232 7.08172 11.2222C7.13285 11.2212 7.18214 11.2337 7.22407 11.2568L7.22442 11.257C8.07133 11.7224 9.02814 11.9655 10.0002 11.9637ZM10.0002 11.9637C10.0006 11.9637 10.001 11.9637 10.0014 11.9637L10.0002 11.3637L9.99899 11.9637C9.99939 11.9637 9.9998 11.9637 10.0002 11.9637Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
  </SvgIcon>
);
