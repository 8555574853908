import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
};

export const PlayIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon className={className} fill="none" size={19.2}>
    <g>
      <path
        d="M4.6 17.2001C4.44087 17.2001 4.28826 17.1369 4.17574 17.0244C4.06321 16.9118 4 16.7592 4 16.6001V3.40009C3.99999 3.29582 4.02716 3.19335 4.07882 3.10278C4.13048 3.01221 4.20485 2.93666 4.2946 2.88359C4.38435 2.83052 4.48638 2.80175 4.59064 2.80012C4.6949 2.7985 4.79778 2.82407 4.88914 2.87431L16.8891 9.47431C16.9833 9.52609 17.0618 9.60219 17.1165 9.69467C17.1712 9.78715 17.2 9.89262 17.2 10.0001C17.2 10.1075 17.1712 10.213 17.1165 10.3055C17.0618 10.3979 16.9833 10.474 16.8891 10.5258L4.88914 17.1258C4.80055 17.1745 4.7011 17.2001 4.6 17.2001Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <rect
        width="19.2"
        height="19.2"
        fill="currentColor"
        transform="translate(0.398438 0.400024)"
      />
    </defs>
  </SvgIcon>
);
