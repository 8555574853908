import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  size?: number;
};

export const TelegramContactIcon: React.FC<Props> = ({ className, size }) => (
  <SvgIcon size={24} className={className}>
    <path
      d="M2 22L3.42705 16.8667C2.54455 15.3587 2.08137 13.6497 2.08389 11.9109C2.08641 10.172 2.55454 8.46436 3.44141 6.95885C4.32828 5.45334 5.60276 4.20283 7.13722 3.33254C8.67169 2.46226 10.4123 2.00274 12.1847 2C14.8138 2.07479 17.3096 3.15194 19.1422 5.00273C20.9748 6.85352 22 9.33221 22 11.9125C22 14.4928 20.9748 16.9715 19.1422 18.8223C17.3096 20.6731 14.8138 21.7502 12.1847 21.825C10.4972 21.8237 8.83644 21.4113 7.35144 20.625L2 22ZM7.59777 18.825C9.33604 19.9299 11.4311 20.3608 13.4754 20.0339C15.5196 19.707 17.3668 18.6457 18.6574 17.0565C19.9481 15.4672 20.5899 13.4638 20.4579 11.4359C20.326 9.40811 19.4298 7.50095 17.9437 6.08558C16.4576 4.67022 14.4879 3.84788 12.4179 3.77858C10.3479 3.70929 8.32562 4.398 6.74463 5.7107C5.16364 7.0234 4.137 8.86619 3.86449 10.8805C3.59199 12.8948 4.0931 14.9365 5.27032 16.6083L4.42089 19.6417L7.59777 18.825Z"
      fill="#2753E2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.49178 11.7876C9.39938 10.4981 11.3378 9.64402 12.307 9.22549C15.0734 8.04624 15.6549 7.84263 16.0287 7.83415C16.1678 7.83102 16.3044 7.87256 16.4192 7.95292C16.4978 8.02576 16.548 8.12521 16.5604 8.23289C16.5837 8.3644 16.5893 8.49856 16.577 8.63162C16.4275 10.2435 15.7795 14.1546 15.4472 15.9531C15.306 16.7167 15.0318 16.9712 14.766 16.9966C14.1845 17.0475 13.7442 16.6064 13.1876 16.2331C12.307 15.6477 11.8168 15.2829 10.9612 14.706C9.97259 14.0443 10.6123 13.6795 11.1772 13.0856C11.3267 12.9329 13.8771 10.5574 13.9269 10.3453C13.9331 10.3137 13.9319 10.2811 13.9232 10.2501C13.9145 10.2191 13.8987 10.1907 13.8771 10.1672C13.8463 10.1477 13.8114 10.1361 13.7752 10.1331C13.7391 10.1302 13.7029 10.136 13.6694 10.1502C13.578 10.1672 12.1824 11.1174 9.46584 12.9923C9.06708 13.2722 8.70986 13.408 8.38587 13.3995C8.02865 13.391 7.34745 13.1959 6.83239 13.0262C6.20933 12.8226 5.71088 12.7123 5.75242 12.356C5.77734 12.1694 6.02657 11.9827 6.49178 11.7876Z"
      fill="#2753E2"
    />
  </SvgIcon>
);
