import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
};

export const ArrowSystemUp: React.FC<Props> = ({ className }) => (
  <SvgIcon width={16} height={16} viewBox="0 0 16 16" fill="none" className={className}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.20377 12C2.34461 12 1.88543 10.9881 2.45119 10.3415L7.24742 4.86009C7.64584 4.40476 8.35417 4.40476 8.75258 4.86009L13.5488 10.3415C14.1146 10.9881 13.6554 12 12.7962 12H3.20377Z"
      fill="currentColor"
    />
  </SvgIcon>
);
