'use client';
import classNames from 'classnames';
import React, { useEffect } from 'react';

type ModalProps = {
  onClose: () => void;
  children?: React.ReactNode;
  className?: string;
  backingClassName?: string;
  hideCrossOnMobile?: boolean;
  notClosable?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  className,
  backingClassName,
  hideCrossOnMobile,
                                              notClosable
}) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  return (
    <div
      className={classNames(
        'sm:pt-0 fixed inset-0 z-top flex h-full w-full items-center justify-center overflow-y-auto bg-black bg-opacity-80',
        backingClassName
      )}
    >
      <div
        className={classNames(
          'relative w-full rounded-3 bg-background-card p-2 shadow-lg md:w-auto md:p-5',
          className
        )}
      >
        <button
          onClick={onClose}
          type="button"
          className={classNames(
            'absolute -top-4 right-0 hidden cursor-pointer items-center justify-center text-text-primary md:right-3 md:top-3 md:flex',
            { ['hidden']: hideCrossOnMobile },
            { ['!hidden']: notClosable }
          )}
          data-modal-hide="default-modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-[18px] w-[18px] md:h-[24px] md:w-[24px]"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M1 1L21 21" stroke="currentColor" strokeWidth="2" />
            <path d="M21 1L1 21" stroke="currentColor" strokeWidth="2" />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <div className="flex flex-col justify-between overflow-hidden">{children}</div>
      </div>
    </div>
  );
};
