import React, { ReactNode, type JSX } from 'react';
import classNames from 'classnames';
import { ArrowLeftIcon } from '@/shared/ui';
import { getDataTestId } from '@/shared/lib/get-data-testid';

type TitleProps = {
  level?: 1 | 2 | 3;
  children: React.ReactNode;
  withButtonBack?: boolean;
  onButtonBackClick?: () => void;
  className?: string;
  rightContent?: ReactNode;
  textAlign?: 'center' | 'left' | 'right';
};

type ButtonBackProps = {
  onBack: () => void;
  className?: string;
};

const ButtonBack: React.FC<ButtonBackProps> = ({ onBack, className }) => (
  <button
    className={classNames('mr-1 block border-none bg-none leading-[12px] md:hidden', className)}
    onClick={onBack}
    aria-label="Back"
    type="button"
    data-testid={getDataTestId({
      block: 'title',
      nameForTargetElement: 'backButton'
    })}
  >
    <ArrowLeftIcon className="text-button-brand" />
  </button>
);

export const Title: React.FC<TitleProps> = ({
  level = 1,
  withButtonBack = false,
  onButtonBackClick,
  children,
  className,
  textAlign = 'center',
  rightContent
}) => {
  const HeadingTag: keyof JSX.IntrinsicElements = `h${level}`;

  return (
    <div
      className={classNames(
        'relative -mt-[16px] flex h-8 w-full items-center md:mb-3 md:mt-0 md:h-auto',
        textAlign === 'left' ? 'justify-start' : 'justify-between',
        className
      )}
      data-testid={getDataTestId({
        block: 'title',
        nameForTargetElement: 'container'
      })}
    >
      {withButtonBack && onButtonBackClick && (
        <ButtonBack
          onBack={onButtonBackClick}
          className={classNames({ absolute: !rightContent })}
        />
      )}
      <HeadingTag
        data-testid={getDataTestId({
          block: 'title',
          nameForTargetElement: 'text'
        })}
        className={classNames('text-text-primary md:mx-0', {
          'typography-mobile-headline-2 md:typography-headline-1': level === 1,
          'md:typography-headline-2 typography-mobile-headline-2': level === 2,
          'typography-mobile-headline-3 md:typography-headline-3': level === 3,
          'mx-auto': textAlign === 'center',
          'ml-0': textAlign === 'left',
          'mr-0': textAlign === 'right'
        })}
      >
        {children}
      </HeadingTag>
      {rightContent && (
        <div className={classNames('ml-1', { 'ml-auto': textAlign === 'left' })}>
          {rightContent}
        </div>
      )}
    </div>
  );
};
