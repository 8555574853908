import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CategoryRecentIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className} fill="none">
    <path
      d="M2.21973 6.14771V10.2388H6.21973"
      stroke="currentColor"
      stroke-width="1.77825"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.6436 17.7203V13.7203H17.6436"
      stroke="currentColor"
      stroke-width="1.77825"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.5487 9.33263C19.0978 8.05833 18.3314 6.91902 17.3211 6.02102C16.3107 5.12302 15.0894 4.4956 13.771 4.19729C12.4525 3.89898 11.0801 3.93952 9.78154 4.31511C8.48304 4.69071 7.30084 5.38912 6.34526 6.34518L2.21973 10.2218M21.7804 13.7782L17.6549 17.6548C16.6993 18.6109 15.5171 19.3093 14.2186 19.6849C12.9201 20.0605 11.5476 20.101 10.2292 19.8027C8.91079 19.5044 7.68943 18.877 6.67909 17.979C5.66875 17.081 4.90236 15.9417 4.45142 14.6674"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);
