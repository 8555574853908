import classNames from 'classnames';
import React, { ReactNode } from 'react';

type ButtonType = 'button' | 'submit' | 'reset';

type Props = {
  children?: ReactNode;
  type?: ButtonType | 'span';
  variant?: 'normal' | 'border' | 'text' | 'empty';
  color?: 'primary' | 'white' | 'black';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'mobile-lg';
  href?: string;
  className?: string;
  name?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>;
  disabled?: boolean;
  dataTestid?: string;
  styles?: React.CSSProperties;
  loading?: boolean;
  loadingText?: string;
};

export const Button: React.FC<Props> = ({
  children,
  type: buttonType = 'button',
  href,
  className: exClassName = '',
  styles,
  size = 'md',
  variant,
  color = 'primary',
  name,
  onClick,
  disabled = false,
  dataTestid,
  loading,
  loadingText
}) => {
  const className = classNames(
    exClassName,
    'md:typography-button-small-semibold rounded-1 font-roboto',
    variant === 'text' ? 'text-left' : 'text-center',
    size === 'mobile-lg' ? 'typography-button-semibold' : 'typography-mobile-button-small-semibold',
    {
      ...getColorConfig({ variant, color }),
      ...getPaddingConfig({ variant, size }),
      'inline-flex items-center': href !== undefined,
      'flex items-center justify-center': href != null || variant === 'text',
      'py-1px': variant !== 'border',
      'transition ease-out duration-300': true,
      'transition-colors duration-200': true,
      '!text-text-disabled !bg-button-disabled !betterhover:hover:bg-grey-lighter cursor-not-allowed':
        disabled
    }
  );

  if (href != null) {
    return (
      <a
        style={styles}
        data-testid={dataTestid}
        href={href}
        className={className}
        aria-disabled={disabled}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  if (buttonType === 'span') {
    return (
      <span
        style={styles}
        data-testid={dataTestid}
        onClick={!disabled ? onClick : undefined}
        className={className}
      >
        {children}
      </span>
    );
  }

  if (loading) {
    return (
      <button
        style={styles}
        data-testid={dataTestid}
        onClick={onClick}
        type={buttonType as ButtonType}
        disabled={true}
        className={className}
        name={name}
      >
        <div className="flex items-center justify-center gap-[12px]">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
          >
            <path
              d="M0 12C-2.07487e-07 14.3734 0.703787 16.6935 2.02236 18.6668C3.34094 20.6402 5.21508 22.1783 7.4078 23.0866C9.60051 23.9948 12.0133 24.2324 14.3411 23.7694C16.6689 23.3064 18.8071 22.1635 20.4853 20.4853C22.1635 18.8071 23.3064 16.6689 23.7694 14.3411C24.2324 12.0133 23.9948 9.60051 23.0866 7.4078C22.1783 5.21509 20.6402 3.34094 18.6668 2.02237C16.6935 0.703788 14.3734 1.79185e-07 12 0V2.4C13.8987 2.4 15.7548 2.96303 17.3335 4.01789C18.9122 5.07275 20.1426 6.57207 20.8692 8.32624C21.5958 10.0804 21.786 12.0107 21.4155 13.8729C21.0451 15.7351 20.1308 17.4456 18.7882 18.7882C17.4456 20.1308 15.7351 21.0451 13.8729 21.4155C12.0106 21.786 10.0804 21.5958 8.32624 20.8692C6.57207 20.1426 5.07275 18.9122 4.01789 17.3335C2.96303 15.7548 2.4 13.8987 2.4 12H0Z"
              fill="url(#paint0_linear_8088_34195)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_8088_34195"
                x1="12"
                y1="12"
                x2="7.97207e-07"
                y2="12"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#D9D9D9" />
                <stop offset="1" stop-color="#737373" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
          {loadingText}
        </div>
      </button>
    );
  }

  return (
    <button
      style={styles}
      data-testid={dataTestid}
      onClick={onClick}
      type={buttonType as ButtonType}
      disabled={disabled}
      className={className}
      name={name}
    >
      {children}
    </button>
  );
};

const getColorConfig = ({ variant, color }: Partial<Props>): Record<string, boolean> => {
  if (variant === 'border') {
    return {
      'border border-current px-7px typography-mobile-button': true,
      'text-primary betterhover:hover:bg-primary betterhover:hover:border-primary betterhover:hover:text-black':
        color === 'primary',
      'text-white betterhover:hover:bg-white betterhover:hover:border-white betterhover:hover:text-black':
        color === 'white',
      'text-black betterhover:hover:bg-black betterhover:hover:border-black betterhover:hover:text-white':
        color === 'black'
    };
  }

  if (variant === 'text') {
    return {
      'text-black': color === 'black'
    };
  }

  if (variant === 'empty') {
    return { 'bg-none border-none p-0': true };
  }

  return {
    'text-black bg-primary betterhover:hover:bg-primary-dark': color === 'primary'
  };
};

const getPaddingConfig = ({ variant, size }: Partial<Props>): Record<string, boolean> => {
  if (variant === 'text') {
    return {
      'px-1 h-4t': true
    };
  }

  return {
    'h-[32px] px-[11px] typography-mobile-button-small':
      size !== 'md' && size !== 'lg' && size !== 'mobile-lg',
    'h-4t md:h-[44px] min-w-[120px] px-2t': size === 'md',
    'h-[44px] md:h-[54px] min-w-[120px] px-2t md:rounded-2': size === 'lg',
    'h-[54px] md:h-[54px] min-w-[120px] px-2t rounded-2': size === 'mobile-lg'
  };
};
