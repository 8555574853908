import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
};

export const CookieIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon className={className} fill="none" size={24}>
    <path
      d="M14.4306 16.8611C15.0058 16.8611 15.4722 16.3947 15.4722 15.8194C15.4722 15.2441 15.0058 14.7778 14.4306 14.7778C13.8553 14.7778 13.3889 15.2441 13.3889 15.8194C13.3889 16.3947 13.8553 16.8611 14.4306 16.8611Z"
      fill="#F6F7F9"
    />
    <path
      d="M8.87501 16.1666C9.45031 16.1666 9.91668 15.7003 9.91668 15.125C9.91668 14.5497 9.45031 14.0833 8.87501 14.0833C8.29971 14.0833 7.83334 14.5497 7.83334 15.125C7.83334 15.7003 8.29971 16.1666 8.87501 16.1666Z"
      fill="#F6F7F9"
    />
    <path
      d="M8.18055 11.3056C8.75585 11.3056 9.22222 10.8392 9.22222 10.2639C9.22222 9.6886 8.75585 9.22223 8.18055 9.22223C7.60526 9.22223 7.13889 9.6886 7.13889 10.2639C7.13889 10.8392 7.60526 11.3056 8.18055 11.3056Z"
      fill="#F6F7F9"
    />
    <path
      d="M12.3472 12.6944C12.9225 12.6944 13.3889 12.228 13.3889 11.6527C13.3889 11.0775 12.9225 10.6111 12.3472 10.6111C11.7719 10.6111 11.3055 11.0775 11.3055 11.6527C11.3055 12.228 11.7719 12.6944 12.3472 12.6944Z"
      fill="#F6F7F9"
    />
    <path
      d="M20.5 12C19.3728 12 18.2918 11.5522 17.4948 10.7552C16.6978 9.95817 16.25 8.87717 16.25 7.75C15.1228 7.75 14.0418 7.30223 13.2448 6.5052C12.4478 5.70817 12 4.62717 12 3.5C10.3189 3.5 8.67547 3.99852 7.27766 4.93251C5.87984 5.8665 4.79037 7.19402 4.14703 8.74719C3.50368 10.3004 3.33535 12.0094 3.66333 13.6583C3.9913 15.3071 4.80085 16.8217 5.9896 18.0104C7.17834 19.1992 8.6929 20.0087 10.3417 20.3367C11.9906 20.6646 13.6996 20.4963 15.2528 19.853C16.806 19.2096 18.1335 18.1202 19.0675 16.7223C20.0015 15.3245 20.5 13.6811 20.5 12Z"
      stroke="#F6F7F9"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);
