export { WalletIcon } from './wallet';
export { SupportIcon } from './support';
export { SearchIcon } from './search';
export { CancelIcon } from './cancel';
export { TelegramIcon } from './telegram';
export { VKIcon } from './vk';
export { YouTubeIcon } from './youtube';
export { FavoriteStarIcon } from './favorite-star';
export { StarIconMobile } from './star-mobile';
export { AddIcon } from './add';
export { QuestionIcon } from './question';
export { ProfileIcon } from './profile';
export { LaunchIcon } from './launch';
export { StarLaunchIcon } from './star-launch';
export { FullScreenIcon } from './fullscreen';
export { MiniScreenIcon } from './miniscreen';
export { ArchiveIcon } from './archive';
export { ArrowIcon } from './arrow';
export { ArrowLeftIcon } from './arrow-left';
export { CrossIcon } from './cross';
export { CrossCircleIcon } from './cross-circle';
export { EditIcon } from './edit';
export { EyeIcon } from './eye';
export { LockIcon } from './lock';
export { HistoryIcon } from './history';
export { FilterIcon } from './filter';
export { BannerClose } from './banner-close';
export { PromoIcon } from './promo';
export { FastGamesIcon } from './fast-games';
export { KenoIcon } from './keno';
export { SportIcon } from './sport';
export { WhatsappIcon } from './whatsapp';
export { TelegramContactIcon } from './telegramContact';
export { Chat } from './chat';
export { SvgIcon } from './svg-icon';
export { FiltersIcon } from './filters';
export { ChevronLeft } from './chevron-left';
export { CheckIcon } from './check';
export { CheckIcon2 } from './check2';
export { DoneRoundIcon } from './done-round';
export { UserIcon } from './user';
export { UserOutlinedIcon } from './user-outlned';
export { ArrowSystemDown } from './arrow-system-down';
export { ArrowSystemUp } from './arrow-system-up';
export { FailIcon } from './fail';
export { CaretDownIcon } from './caret-down';
export { PlayIcon } from './play';
export { FireIcon } from './fire';
export { MoneyIcon } from './money';
export { ChevronLeftMobile } from './chevron-left-mobile';
export { CaretLeftIcon } from './caret-left';
export { ChevronLeftDesktop } from './chevron-left-desktop';
export { CategoryBonusPurchaseIcon } from './category-bonus-purchase';
export { CategoryLobbyIcon } from './category-lobby';
export { CategorySlotsIcon } from './category-slots';
export { CategoryRecentIcon } from './category-recent';
export { CategoryFavoritesIcon } from './category-favorites';
export { CategoryDropsIcon } from './category-drops';
export { CategoryLiveIcon } from './category-live';
export { CategoryNewIcon } from './category-new';
export { CategoryPopularIcon } from './category-popular';
export { CategoryDefaultIcon } from './category-default';
export { CookieIcon } from './cookie';
export { HeaderSearchIcon } from './header-search';
