import React from 'react';
import classNames from 'classnames';
import classnames from 'classnames';

type BlockProps = {
  children: React.ReactNode;
  className?: string;
};

export const Block: React.FC<BlockProps> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        'mb-[8px] rounded-s bg-background-card p-[16px] last:mb-0 md:mb-[16px] md:rounded-xm md:first:rounded-t-none',
        className
      )}
    >
      {children}
    </div>
  );
};
