import { Nullable } from '@/shared/ui/swiping-pane/types';

export type MoveEvent = React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>;

export const isMouseEvent = (event: MoveEvent): event is React.MouseEvent<HTMLDivElement> => {
  return 'clientX' in event && 'clientY' in event;
};

export const getCoords = (event: MoveEvent): [number, number] => {
  if (isMouseEvent(event)) {
    return [event.clientX, event.clientY];
  }

  const touch = event.touches[0];
  return [touch.clientX, touch.clientY];
};

export const parsePixels = (str: string) => {
  return parseFloat(str.replace('px', ''));
};

export const getPadding = (element: Nullable<HTMLDivElement>) => {
  if (element) {
    const styles = window.getComputedStyle(element);
    const value = parsePixels(styles.paddingRight);
    return value;
  }

  return 0;
};

export const sum = (acc: number, value: Nullable<number>) => {
  if (value != null) {
    return acc + value;
  }

  return acc;
};

export const getMaxOffset = (
  container: Nullable<HTMLDivElement>,
  track: Nullable<HTMLDivElement>
) => {
  const containerWidth = container?.clientWidth ?? 0;
  const scrollWidth = track?.scrollWidth ?? 0;
  const paddingWidth = getPadding(track);
  return scrollWidth + paddingWidth - containerWidth;
};

export const getNearest = (target: number, sizes: number[]) => {
  if (target <= 0) {
    return 0;
  }

  let nearest = sizes.length;
  let acc = 0;

  sizes.forEach((width, index) => {
    if (acc >= target) return;

    acc += width;

    if (acc >= target) {
      nearest = index + 1;
    }
  });

  return nearest;
};

export const recalculateOffset = (
  currentIndex: number,
  gap: number,
  maxOffset: number,
  elements: Nullable<HTMLElement>[]
) => {
  const gapWidth = currentIndex * gap;
  const possibleOffset = getElementsWidths(elements)
    .slice(0, currentIndex)
    .reduce<number>(sum, gapWidth);
  return Math.min(possibleOffset, maxOffset);
};

export const getElementsWidths = (elements: Nullable<HTMLElement>[]) =>
  elements.map(getWidth).filter((w) => w > 0);

export const getWidth = (element: Nullable<HTMLElement>) => element?.offsetWidth ?? 0;

export const applyTransformTranslateX = (
  element: Nullable<HTMLElement>,
  offset: number,
  transition: string
) => {
  if (element != null) {
    element.style.transition = transition;
    element.style.transform = `translate3d(${-offset}px,0,0)`;
  }
};
