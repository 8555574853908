import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const EditIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} fill="#5E6165" className={className}>
    <rect width="24" height="24" rx="4" />
    <g opacity="0.7 ">
      <path
        d="M10.3113 16.0264C9.72822 15.0705 8.92166 14.2705 7.96094 13.695C8.02471 13.625 8.0752 13.5657 8.13012 13.5113C9.75403 11.8879 11.3759 10.2646 12.9957 8.64128C13.1285 8.50846 13.2255 8.49429 13.3867 8.58372C14.2597 9.0561 14.9731 9.77649 15.4369 10.6539C15.4997 10.7721 15.5104 10.8492 15.4028 10.9563C13.7286 12.6215 12.0576 14.2891 10.3897 15.9591C10.3738 15.975 10.3561 15.9883 10.3113 16.0264Z"
        fill="black"
      />
      <path
        d="M16.2121 10.1586C15.6232 9.19026 14.806 8.38085 13.832 7.80105C14.0978 7.53984 14.3453 7.2928 14.6 7.05373C14.6245 7.03365 14.6529 7.01869 14.6833 7.00972C14.7137 7.00074 14.7456 6.99795 14.7771 7.00149C15.0951 7.05373 15.418 7.09402 15.7263 7.18301C16.4659 7.3964 16.7918 7.96397 16.9309 8.67101C17.0558 9.30411 17.0527 9.30411 16.5983 9.75923C16.4761 9.88364 16.3565 10.0107 16.2121 10.1586Z"
        fill="black"
      />
      <path
        d="M7.00155 16.9981C7.00155 16.2242 6.99668 15.4817 7.00421 14.7401C7.00642 14.4896 7.27215 14.3488 7.49138 14.4679C8.36292 14.9286 9.07511 15.6414 9.53486 16.5133C9.64558 16.7205 9.50873 16.9919 9.274 16.9945C8.52596 17.0038 7.7766 16.9981 7.00155 16.9981Z"
        fill="black"
      />
    </g>
  </SvgIcon>
);
