import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const HeaderSearchIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className} fill="none" viewBox="0 0 24 24">
    <g clip-path="url(#clip0_14016_92461)">
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H8C3.58172 24 0 20.4183 0 16V8Z"
        fill="#B2B3B7"
      />
      <path
        d="M17 17L14.6391 14.635M15.9474 11.4737C15.9474 13.9444 13.9444 15.9474 11.4737 15.9474C9.00294 15.9474 7 13.9444 7 11.4737C7 9.00294 9.00294 7 11.4737 7C13.9444 7 15.9474 9.00294 15.9474 11.4737Z"
        stroke="#323338"
        strokeWidth="1.78947"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14016_92461">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H8C3.58172 24 0 20.4183 0 16V8Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
