import React, { type JSX } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

type Props = {
  visible: boolean;
  onClick: () => void;
};

export const PasswordToggle: React.FC<Props> = ({ visible, onClick }): JSX.Element => {
  return (
    <>
      <button className="js-password-label h-6 w-6 cursor-pointer" onClick={onClick}>
        {visible ? (
          <EyeSlashIcon className="text-slate-700 h-6 w-6" />
        ) : (
          <EyeIcon className="text-slate-400 h-6 w-6" />
        )}
      </button>
    </>
  );
};
