'use client';

import React, { useState, useId } from 'react';
import { Tooltip } from 'react-tooltip';
import className from 'classnames';

import { QuestionIcon } from '@/shared/ui';

type Props = {
  children: React.ReactNode;
  openOnHover?: boolean;
};

export const Hint: React.FC<Props> = ({ children, openOnHover }) => {
  const [isOpen, setIsOpen] = useState(false);
  const id = useId();

  const openHandler = (value: boolean) => {
    setIsOpen(value);
  };

  return (
    <>
      <button data-tooltip-id={id} type="button" className="flex h-[24px] w-[24px] p-none">
        <QuestionIcon
          className={className('cursor-pointer hover:fill-button-brand', {
            'fill-button-brand': isOpen
          })}
        />
      </button>
      <Tooltip
        className="!flex flex-col gap-xxs !rounded-xxs !bg-background-tag !p-xs opacity-0 shadow-[0_0_10px_0_#0D0D0D80]"
        classNameArrow="!w-[8.5px] !h-[8.5px] !rounded-br-[2px]"
        id={id}
        place="right"
        setIsOpen={openHandler}
        openOnClick={!openOnHover}
        clickable
      >
        {children}
      </Tooltip>
    </>
  );
};
