import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  size?: number;
};

export const FavoriteStarIcon: React.FC<Props> = ({ className, size = 25 }) => (
  <SvgIcon size={size} fill="none" stroke="currentColor" className={className} viewBox="0 0 26 25">
    <path
      d="M12.1033 1.81699C12.4701 1.07374 13.5299 1.07374 13.8967 1.81699L16.7349 7.56769C16.8805 7.86284 17.1621 8.06741 17.4878 8.11473L23.8341 9.0369C24.6543 9.15609 24.9818 10.1641 24.3883 10.7426L19.7961 15.2189C19.5604 15.4486 19.4529 15.7796 19.5085 16.104L20.5926 22.4247C20.7327 23.2416 19.8753 23.8645 19.1416 23.4788L13.4653 20.4946C13.174 20.3415 12.826 20.3415 12.5347 20.4946L6.85837 23.4788C6.12474 23.8645 5.26731 23.2416 5.40742 22.4247L6.4915 16.104C6.54713 15.7796 6.43959 15.4486 6.2039 15.2189L1.61169 10.7426C1.01817 10.1641 1.34568 9.15609 2.1659 9.0369L8.51218 8.11473C8.83789 8.06741 9.11946 7.86284 9.26512 7.56769L12.1033 1.81699Z"
      stroke="currentColor"
      strokeWidth="2.08889"
    />
  </SvgIcon>
);
