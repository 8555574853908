import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

export const HistoryIcon: React.FC<Props> = ({ className, size = 29 }) => (
  <SvgIcon size={size} className={className} fill="none" viewBox="0 0 20 20">
    <path
      d="M4.75351 15.2435L4.32925 14.8212L3.90592 15.2445L3.0989 16.0515V4.56348C3.10029 4.17502 3.25553 3.80292 3.53066 3.52862C3.80597 3.25414 4.17891 3.1 4.56769 3.1H15.4364C15.8243 3.1 16.1963 3.25408 16.4705 3.52836C16.7448 3.80264 16.8989 4.17462 16.8989 4.56249V16.0514L16.092 15.2445L15.6686 14.8211L15.2444 15.2435L14.2499 16.2337L13.2607 15.2445L12.8374 14.8212L12.4131 15.2435L11.4177 16.2346L10.4222 15.2435L9.9989 14.8221L9.57558 15.2435L8.58108 16.2337L7.5919 15.2445L7.16856 14.8211L6.7443 15.2436L5.74894 16.2346L4.75351 15.2435Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
    <path d="M7.4989 6.875H14.3739" stroke="currentColor" strokeWidth="1.2" />
    <path d="M7.4989 8.75H14.3739" stroke="currentColor" strokeWidth="1.2" />
    <path d="M7.4989 10.625H14.3739" stroke="currentColor" strokeWidth="1.2" />
    <path d="M4.9989 6.875H6.2489" stroke="currentColor" strokeWidth="1.2" />
    <path d="M4.9989 8.75H6.2489" stroke="currentColor" strokeWidth="1.2" />
    <path d="M4.9989 10.625H6.2489" stroke="currentColor" strokeWidth="1.2" />
  </SvgIcon>
);
