import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CategoryLobbyIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className}>
    <path
      d="M3 13.8L10.2 21M10.2 13.8L3 21M21 13.8H13.8V21H21V13.8ZM7.05 3L11.1 10.2H3L7.05 3ZM17.4 10.2C18.3548 10.2 19.2705 9.82072 19.9456 9.14558C20.6207 8.47045 21 7.55478 21 6.6C21 5.64522 20.6207 4.72955 19.9456 4.05442C19.2705 3.37928 18.3548 3 17.4 3C16.4452 3 15.5295 3.37928 14.8544 4.05442C14.1793 4.72955 13.8 5.64522 13.8 6.6C13.8 7.55478 14.1793 8.47045 14.8544 9.14558C15.5295 9.82072 16.4452 10.2 17.4 10.2Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
