import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
};

export const ArrowSystemDown: React.FC<Props> = ({ className }) => (
  <SvgIcon width={16} height={16} viewBox="0 0 16 16" fill="none" className={className}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.20377 4C2.34461 4 1.88543 5.01192 2.45119 5.6585L7.24742 11.1399C7.64584 11.5952 8.35417 11.5952 8.75258 11.1399L13.5488 5.6585C14.1146 5.01192 13.6554 4 12.7962 4H3.20377Z"
      fill="currentColor"
    />
  </SvgIcon>
);
