import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const rowsPerPageOptions = [10, 20, 30, 40, 50];

type Props = {
  currentPage: number;
  currentPageSize: number;
  countPages: number;
  setCurrentPage: (page: number) => void;
  setPageSize: (page: number) => void;
};

export const Pagination: React.FC<Props> = ({
  currentPage,
  setCurrentPage,
  currentPageSize,
  setPageSize,
  countPages
}) => {
  return (
    <div className="border-gray-200 sm:px-6 flex items-center justify-between border-t bg-white px-4 py-3">
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between hidden">
        <div>
          <p className="text-gray-700 text-sm">
            Page <span className="font-medium">{currentPage}</span> to{' '}
            <span className="font-medium">{countPages}</span>
          </p>
        </div>
        <div>
          <nav
            className="-space-x-px isolate inline-flex rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
              className="text-gray-400 ring-gray-300 hover:bg-gray-50 focus:z-20 relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset focus:outline-offset-0"
            >
              <span className="sr-only">First</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            &nbsp;
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="text-gray-400 ring-gray-300 hover:bg-gray-50 focus:z-20 relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            &nbsp;
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === countPages}
              className="text-gray-400 ring-gray-300 hover:bg-gray-50 focus:z-20 relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            &nbsp;
            <button
              onClick={() => setCurrentPage(countPages)}
              disabled={currentPage === countPages}
              className="text-gray-400 ring-gray-300 hover:bg-gray-50 focus:z-20 relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset focus:outline-offset-0"
            >
              <span className="sr-only">Last</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            &nbsp;
            <div className="mr-3 flex items-center gap-1">
              <p className="text-gray-700 text-sm">Page:</p>
              <input
                type="number"
                value={currentPage}
                onChange={(e) => {
                  const page = Number(e.target.value) > 0 ? Number(e.target.value) : 1;
                  setCurrentPage(page);
                }}
                className="py-1.5 text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6 block w-full rounded-md border-0 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
              />
            </div>
            <div>
              <select
                value={currentPageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="text-gray-900 ring-gray-300 focus:ring-indigo-600 sm:text-sm sm:leading-6 ml-3 block rounded-md border-0 pl-3 ring-1 ring-inset focus:ring-2"
              >
                {rowsPerPageOptions.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
