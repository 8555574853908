import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CategoryBonusPurchaseIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className}>
    <path
      d="M15.8879 8.41603C15.8879 10.0265 12.9867 11.3321 9.40781 11.3321C5.82896 11.3321 2.92773 10.0265 2.92773 8.41603M15.8879 8.41603C15.8879 6.80555 12.9867 5.5 9.40781 5.5C5.82896 5.5 2.92773 6.80555 2.92773 8.41603M15.8879 8.41603V11.6561C15.8879 13.2664 12.9864 14.5721 9.40781 14.5721C5.82919 14.5721 2.92773 13.2664 2.92773 11.6561V8.41603M6.81578 11.0891V14.3291M15.8879 9.44637C18.846 9.71691 21.0719 10.893 21.0719 12.3041M21.0719 12.3041C21.0719 13.9144 18.1705 15.2201 14.5919 15.2201C13.0042 15.2201 11.5495 14.9633 10.4227 14.5365M21.0719 12.3041L21.0719 15.5441C21.0719 17.1544 18.1705 18.4601 14.5919 18.4601C11.0132 18.4601 8.11179 17.1544 8.11179 15.5441V14.5138M17.1839 14.9771V18.2171M11.9998 11.0891V18.2171"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
