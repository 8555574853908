import { SvgIcon } from './svg-icon';

export const YouTubeIcon = () => (
  <SvgIcon size={50}>
    <rect
      fill="none"
      opacity="0.2"
      x="0.5"
      y="0.5"
      width="49"
      height="49"
      rx="4.5"
      stroke="currentColor"
    />
    <path d="M36.8796 22.9063C36.8919 22.2881 36.852 21.67 36.7604 21.059C36.6413 20.32 36.6413 19.5811 36.5221 18.9653C36.399 18.2969 36.0635 17.6901 35.5687 17.2411C35.1084 16.788 34.5273 16.4877 33.9004 16.379C30.93 16.0864 27.9462 15.963 24.9627 16.0096C21.9791 15.963 18.9954 16.0864 16.025 16.379C15.398 16.4877 14.8169 16.788 14.3566 17.2411C13.8618 17.6901 13.5263 18.2969 13.4032 18.9653C13.2841 19.5811 13.1649 20.1969 13.0457 21.059C12.9848 21.6732 12.9848 22.2922 13.0457 22.9063V27.0937C13.0334 27.7119 13.0733 28.33 13.1649 28.941C13.1784 29.6452 13.2582 30.3464 13.4032 31.0347C13.5263 31.7031 13.8618 32.3099 14.3566 32.7589C14.8169 33.212 15.398 33.5123 16.025 33.621C18.9954 33.9137 21.9791 34.037 24.9627 33.9904C27.9462 34.037 30.93 33.9137 33.9004 33.621C34.5273 33.5123 35.1084 33.212 35.5687 32.7589C36.0635 32.3099 36.399 31.7031 36.5221 31.0347C36.6413 30.4189 36.7604 29.8031 36.8796 28.941C36.9679 28.3296 37.0077 27.7118 36.9988 27.0937V25C36.9093 24.3061 36.8695 23.6063 36.8796 22.9063ZM29.7294 25.7389L22.9368 30.1726C22.8176 30.2957 22.6984 30.2957 22.4601 30.2957C22.3954 30.3038 22.3298 30.2969 22.268 30.2756C22.2062 30.2543 22.1497 30.2191 22.1026 30.1726C21.9503 30.1294 21.8183 30.0309 21.7307 29.8951C21.6432 29.7594 21.6059 29.5955 21.6259 29.4336V20.7018C21.6059 20.54 21.6432 20.3761 21.7307 20.2404C21.8183 20.1046 21.9503 20.0061 22.1026 19.9629C22.2337 19.9017 22.3759 19.87 22.5197 19.87C22.6635 19.87 22.8057 19.9017 22.9368 19.9629L29.7294 24.3965C29.8535 24.4689 29.9538 24.5779 30.0175 24.7095C30.0811 24.8412 30.1054 24.9895 30.087 25.1355C30.087 25.2603 30.0536 25.3828 29.9905 25.4893C29.9274 25.5958 29.8371 25.6822 29.7294 25.7389Z" />
  </SvgIcon>
);
