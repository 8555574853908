import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  size?: number;
};

export const CancelIcon: React.FC<Props> = ({ className, size = 12 }) => (
  <SvgIcon size={size} stroke="currentColor" className={className}>
    <path
      d="M3 3L13.6 13.6M3 13.6L13.6 3"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
