import { ReactNode } from 'react';

type Props = {
  size?: number;
  width?: number;
  height?: number;
  fill?: string;
  stroke?: string;
  className?: string;
  viewBox?: string;
  id?: string;
  fillRule?: 'nonzero' | 'evenodd' | 'inherit';
  clipRule?: string | number;
  children: ReactNode;
};

export const SvgIcon: React.FC<Props> = ({
  children,
  className,
  id,
  fill = 'currentColor',
  stroke = '',
  size = 24,
  width = size,
  height = size,
  viewBox = `0 0 ${width} ${height}`,
  fillRule,
  clipRule
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      fill={fill}
      stroke={stroke}
      fillRule={fillRule}
      clipRule={clipRule}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      style={{
        verticalAlign: 'middle'
      }}
    >
      {children}
    </svg>
  );
};
