import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const Chat: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_3118_79370" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H15L20 22V18C21.1046 18 22 17.1046 22 16V6C22 4.89543 21.1046 4 20 4H4Z"
        />
      </mask>
      <path
        d="M15 18L15.6247 17.2191L15.3508 17H15V18ZM20 22L19.3753 22.7809L21 24.0806V22H20ZM20 18V17H19V18H20ZM3 6C3 5.44772 3.44772 5 4 5V3C2.34315 3 1 4.34315 1 6H3ZM3 16V6H1V16H3ZM4 17C3.44772 17 3 16.5523 3 16H1C1 17.6569 2.34315 19 4 19V17ZM15 17H4V19H15V17ZM20.6247 21.2191L15.6247 17.2191L14.3753 18.7809L19.3753 22.7809L20.6247 21.2191ZM19 18V22H21V18H19ZM21 16C21 16.5523 20.5523 17 20 17V19C21.6569 19 23 17.6569 23 16H21ZM21 6V16H23V6H21ZM20 5C20.5523 5 21 5.44772 21 6H23C23 4.34315 21.6569 3 20 3V5ZM4 5H20V3H4V5Z"
        fill="black"
        mask="url(#path-1-inside-1_3118_79370)"
      />
    </svg>
  </SvgIcon>
);
