'use client';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Title } from '@/shared/ui';

type ModalProps = {
  onClose: () => void;
  children?: React.ReactNode;
  title?: string;
  className?: string;
};

export const ModalMobile: React.FC<ModalProps> = ({ onClose, children, title, className }) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  return (
    <div className="sm:pt-0 fixed inset-0 top-[50px] z-top flex h-full w-full justify-center overflow-y-auto bg-black">
      <div className={classNames('relative bg-background-main p-2 shadow-lg md:p-5', className)}>
        <Title withButtonBack onButtonBackClick={onClose}>
          {title}
        </Title>
        <div className="flex flex-col justify-between overflow-hidden">{children}</div>
      </div>
    </div>
  );
};
