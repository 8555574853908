import { SvgIcon } from '@/shared/ui';

type Props = {
  className?: string;
};

export const FailIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon width={16} height={16} viewBox="0 0 16 16" fill="none" className={className}>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
      fill="#FF2F55"
    />
    <path
      d="M4.25 4.25L12.2 12.2M4.25 12.2L12.2 4.25"
      stroke="#191A1F"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
