import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const SearchIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={16} fill="none" className={className}>
    <path
      d="M14.6663 14.6666L11.5185 11.5133M13.2628 7.29816C13.2628 10.5925 10.5922 13.2631 7.29792 13.2631C4.00359 13.2631 1.33301 10.5925 1.33301 7.29816C1.33301 4.00383 4.00359 1.33325 7.29792 1.33325C10.5922 1.33325 13.2628 4.00383 13.2628 7.29816Z"
      stroke="currentColor"
      strokeWidth="1.78947"
      strokeLinecap="round"
    />
  </SvgIcon>
);
