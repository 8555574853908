import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const ProfileIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className} fill="none" viewBox="0 0 24 24">
    <path
      d="M11.9232 12.8515C15.4706 12.8515 18.3464 9.97462 18.3464 6.42577C18.3464 2.87691 15.4706 0 11.9232 0C8.37575 0 5.5 2.87691 5.5 6.42577C5.5 9.97462 8.37575 12.8515 11.9232 12.8515Z"
      fill="currentColor"
    />
    <path
      d="M21.7837 16.7234C20.5787 15.3346 18.9613 14.1476 17.3895 13.1974C17.1789 13.0703 16.9364 13.0006 16.688 12.9958C16.4396 12.9909 16.1944 13.0511 15.9786 13.1698C14.7667 13.8358 13.3952 14.1847 12.0003 14.1819C10.6051 14.1848 9.23347 13.8359 8.02139 13.1698C7.80559 13.0511 7.56044 12.9909 7.312 12.9958C7.06357 13.0006 6.82115 13.0703 6.61051 13.1974C5.03575 14.1476 3.42132 15.334 2.21634 16.7234C0.341782 18.8832 0 21.3375 0 21.3375V22.5633C0.000463704 22.9445 0.157855 23.3099 0.437598 23.5792C0.717342 23.8486 1.09656 23.9999 1.49194 23.9999H22.5081C22.9036 23.9999 23.283 23.8485 23.5628 23.5788C23.8426 23.3092 23.9998 22.9435 24 22.5622V21.3364C24 21.3364 23.6582 18.8832 21.7837 16.7234Z"
      fill="currentColor"
    />
  </SvgIcon>
);
