import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
  arrowClassName?: string;
};

export const ChevronLeftDesktop: React.FC<Props> = ({ className }) => (
  <SvgIcon width={12} height={20} className={className} fill="none">
    <path
      d="M10.5 1L1.5 10L10.5 19"
      stroke="#F6F7F9"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);
