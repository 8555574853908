import React, { HTMLAttributeAnchorTarget } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

type ButtonProps = {
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  href?: string;
  className?: string;
  size?: 'xs' | 's' | 'm' | 'l' | 'custom';
  variant?: 'light' | 'primary' | 'secondary' | 'transparent' | 'outline' | 'border' | 'sidebar';
  color?: 'primary' | 'white' | 'black';
  dataTestId?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  name?: string;
  target?: HTMLAttributeAnchorTarget;
  id?: string;
};

export const Button: React.FC<ButtonProps> = ({
  className,
  size = 'm',
  variant,
  color = 'primary',
  children,
  href,
  onClick,
  dataTestId,
  disabled,
  name,
  target,
  id = ''
}) => {
  const style = classNames(
    'cursor-pointer drop-shadow-lg disabled:pointer-events-none',
    'flex items-center justify-center',
    {
      'h-[56px] rounded-s': size === 'l',
      'h-[36px] rounded-s md:typography-button-small-semibold': size === 's',
      'md:typography-button-small-semibold md:h-[44px] rounded-1 typography-mobile-info h-[32px]':
        size === 'm',
      'h-[32px] rounded-xxs': size === 'xs'
    },
    {
      'md:hover:bg-button-brand-hover bg-button-brand active:bg-button-brand-click disabled:bg-button-disabled disabled:text-disabled':
        variant === 'primary',
      'md:hover:bg-button-brand md:hover:text-text-secondary text-button-brand border-button-brand active:bg-button-brand-hover active:border-button-brand-hover active:text-text-secondary disabled:border-button-disabled disabled:text-text-disabled':
        variant === 'outline',
      'text-button-brand hover:text-button-brand-hover active:text-button-brand-click disabled:text-button-disabled disabled:text-disabled underline disabled:no-underline active:no-underline hover:no-underline':
        variant === 'transparent',
      'hover:bg-button-light-hover bg-button-light text-text-secondary': variant === 'light',
      'bg-background-card text-icon-brand hover:bg-background-tag active:bg-button-brand-click disabled:bg-button-disabled disabled:text-disabled':
        variant === 'secondary',
      'text-white betterhover:hover:bg-white betterhover:hover:border-white betterhover:hover:text-black':
        variant === 'border' && color === 'white',
      'text-black betterhover:hover:bg-black betterhover:hover:border-black betterhover:hover:text-white':
        variant === 'border' && color === 'black',
      'text-primary betterhover:hover:bg-primary betterhover:hover:border-primary betterhover:hover:text-black':
        variant === 'border' && color === 'primary',
      'border border-current rounded-[8px] typography-mobile-button py-[8px]': variant === 'border',
      'text-text-primary hover:text-text-brand active:text-text-brand bg-background-body active:bg-background-card group cursor-pointer':
        variant === 'sidebar'
    },
    className
  );

  if (href) {
    return (
      <Link
        scroll
        id={id}
        href={href}
        className={style}
        data-testid={dataTestId}
        target={target}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      id={id}
      onClick={onClick}
      className={style}
      data-testid={dataTestId}
      disabled={disabled}
      name={name}
    >
      {children}
    </button>
  );
};
