import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const CategorySlotsIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={24} className={className}>
    <path
      d="M4.92512 10.7964L3.44078 13.9146C3.35938 14.0857 3.44863 14.2891 3.62956 14.3451L4.1131 14.4946C4.18571 14.517 4.26402 14.5121 4.33324 14.4807C4.40245 14.4493 4.45777 14.3937 4.48873 14.3243L6.27147 10.3265C6.28922 10.2866 6.2984 10.2435 6.29841 10.1999V9.80231C6.29841 9.7615 6.29038 9.72108 6.27476 9.68338C6.25915 9.64567 6.23626 9.61141 6.2074 9.58255C6.17854 9.5537 6.14428 9.53081 6.10657 9.51519C6.06887 9.49958 6.02846 9.49154 5.98765 9.49155H3.2673C3.22649 9.49154 3.18608 9.49958 3.14837 9.51519C3.11067 9.53081 3.07641 9.5537 3.04755 9.58255C3.01869 9.61141 2.9958 9.64567 2.98019 9.68338C2.96457 9.72108 2.95654 9.7615 2.95654 9.80231V10.0414C2.95654 10.0822 2.96457 10.1226 2.98019 10.1603C2.9958 10.198 3.01869 10.2322 3.04755 10.2611C3.07641 10.29 3.11067 10.3129 3.14837 10.3285C3.18608 10.3441 3.22649 10.3521 3.2673 10.3521H4.64452C4.87291 10.3521 5.02326 10.5902 4.92512 10.7964Z"
      fill="currentColor"
    />
    <path
      d="M12.2894 10.7964L10.805 13.9146C10.7236 14.0857 10.8129 14.2891 10.9938 14.3451L11.4774 14.4946C11.55 14.517 11.6283 14.5121 11.6975 14.4807C11.7667 14.4493 11.822 14.3937 11.853 14.3243L13.6357 10.3265C13.6535 10.2866 13.6627 10.2435 13.6627 10.1999V9.80231C13.6627 9.7615 13.6546 9.72108 13.639 9.68338C13.6234 9.64567 13.6005 9.61141 13.5717 9.58255C13.5428 9.5537 13.5085 9.53081 13.4708 9.51519C13.4331 9.49958 13.3927 9.49154 13.3519 9.49155H10.6316C10.5907 9.49154 10.5503 9.49958 10.5126 9.51519C10.4749 9.53081 10.4407 9.5537 10.4118 9.58255C10.383 9.61141 10.3601 9.64567 10.3444 9.68338C10.3288 9.72108 10.3208 9.7615 10.3208 9.80231V10.0414C10.3208 10.0822 10.3288 10.1226 10.3444 10.1603C10.3601 10.198 10.383 10.2322 10.4118 10.2611C10.4407 10.29 10.4749 10.3129 10.5126 10.3285C10.5503 10.3441 10.5907 10.3521 10.6316 10.3521H12.0088C12.2372 10.3521 12.3875 10.5902 12.2894 10.7964Z"
      fill="currentColor"
    />
    <path
      d="M19.6531 10.7964L18.1688 13.9146C18.0874 14.0857 18.1767 14.2891 18.3576 14.3451L18.8411 14.4946C18.9137 14.517 18.9921 14.5121 19.0613 14.4807C19.1305 14.4493 19.1858 14.3937 19.2168 14.3243L20.9995 10.3265C21.0173 10.2866 21.0264 10.2435 21.0264 10.1999V9.80231C21.0264 9.7615 21.0184 9.72108 21.0028 9.68338C20.9872 9.64567 20.9643 9.61141 20.9354 9.58255C20.9066 9.5537 20.8723 9.53081 20.8346 9.51519C20.7969 9.49958 20.7565 9.49154 20.7157 9.49155H17.9953C17.9545 9.49154 17.9141 9.49958 17.8764 9.51519C17.8387 9.53081 17.8044 9.5537 17.7756 9.58255C17.7467 9.61141 17.7238 9.64567 17.7082 9.68338C17.6926 9.72108 17.6846 9.7615 17.6846 9.80231V10.0414C17.6846 10.0822 17.6926 10.1226 17.7082 10.1603C17.7238 10.198 17.7467 10.2322 17.7756 10.2611C17.8044 10.29 17.8387 10.3129 17.8764 10.3285C17.9141 10.3441 17.9545 10.3521 17.9953 10.3521H19.3725C19.6009 10.3521 19.7513 10.5902 19.6531 10.7964Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.9787 3.4879H3.02148C2.4692 3.4879 2.02148 3.93562 2.02148 4.4879V19.5121C2.02148 20.0644 2.4692 20.5121 3.02148 20.5121H20.9787C21.531 20.5121 21.9787 20.0644 21.9787 19.5121V4.4879C21.9787 3.93561 21.531 3.4879 20.9787 3.4879ZM3.02148 2.4879C1.91691 2.4879 1.02148 3.38333 1.02148 4.4879V19.5121C1.02148 20.6167 1.91691 21.5121 3.02148 21.5121H20.9787C22.0833 21.5121 22.9787 20.6167 22.9787 19.5121V4.4879C22.9787 3.38333 22.0833 2.4879 20.9787 2.4879H3.02148ZM6.03092 19.1344V19.4485H2.71579V19.1344C2.71579 18.7084 3.06874 18.3631 3.50415 18.3631H5.2425C5.67797 18.3631 6.03092 18.7084 6.03092 19.1344ZM12.6044 18.3631H10.8661C10.4307 18.3631 10.0777 18.7084 10.0777 19.1344V19.4485H13.3928V19.1344C13.3928 18.7084 13.0398 18.3631 12.6044 18.3631ZM19.9663 18.3631H18.228C17.7926 18.3631 17.4396 18.7084 17.4396 19.1344V19.4485H20.7548V19.1344C20.7548 18.7084 20.4018 18.3631 19.9663 18.3631ZM6.11647 4.64371V4.94924C6.11647 5.36361 5.76082 5.6995 5.32203 5.6995H3.57037C3.13162 5.6995 2.77597 5.36361 2.77597 4.94924V4.64371H6.11647ZM10.9886 5.6995H12.7403C13.179 5.6995 13.5347 5.36361 13.5347 4.94924V4.64371H10.1942V4.94924C10.1942 5.36361 10.5499 5.6995 10.9886 5.6995ZM18.4069 5.6995H20.1585C20.5973 5.6995 20.953 5.36361 20.953 4.94924V4.64371H17.6125V4.94924C17.6125 5.36361 17.9681 5.6995 18.4069 5.6995Z"
      fill="currentColor"
    />
  </SvgIcon>
);
