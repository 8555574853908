import { SvgIcon } from './svg-icon';
import { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const CheckIcon2: FC<Props> = ({ className, color = '#FFFFFF' }) => {
  return (
    <SvgIcon width={15} height={14} viewBox="0 0 15 14" fill="none" className={className}>
      <path
        d="M1.875 7L6.25 11.375L13.5417 2.625"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
