// копируется из apps/main/src/shared/routes/config/index.ts
export type Page =
  | 'home'
  | 'register'
  | 'login'
  | 'recovery'
  | 'casino'
  | 'casinoCategory'
  | 'casinoGame'
  | 'promo'
  | 'sport'
  | 'sportPrematch'
  | 'sportLive'
  | 'profile'
  | 'operationsHistory';

// берется из названий в apps/main/src
export type Block =
  /** Виджеты/Блоки casino **/
  // виджет 'Сейчас выйгрывают'
  | 'winners'
  | 'moreGames'
  | 'categoryPreviewPane'
  // плашка фильтров для игр
  | 'lobbyNavigation'
  // окно iframe игры
  | 'gameLauncher'
  | 'categoryPreview'
  | 'categoryGames'
  | 'favoriteGames'
  | 'gamesByProvider'
  | 'seoBlock'
  | 'gameFilter'
  | 'providers'
  | 'sportBook'
  | 'qaBar'
  | 'banners'
  | 'gameCard'
  | 'mobileNavBar'
  | 'mobileMenu'
  | 'sidebar'

  /** Виджеты/Блоки общие **/
  | 'header'
  | 'footer'
  | 'tabs'
  | 'form'
  | 'title'
  | 'card';

/**
 * modification - это поле применяют для пробрасывания уникального параметра в списке одинаковых страниц/блоков/элементов
 * Например, отображаются табы и мы знаем что у каждого таба есть уникальное поле title (название таба).
 * Допустим для таба data-testid="profile-userInfoTabs-tab"
 * И таких табов три: 'Main data', 'Change password', 'Notifications'.
 * И в таком случае тестеру будет очень удобно пробрасывать title в modification
 * data-testid="profile-userInfoTabs-tab--Main data"
 * data-testid="profile-userInfoTabs-tab--Change password"
 * data-testid="profile-userInfoTabs-tab--Notifications"
 * **/

type ParametersForTestIdOfElement = {
  page?: Page;
  block?: Block;
  nameForTargetElement: string;
  modification?: string;
};

type ParametersForTestIdOfBlock = {
  page?: Page;
  nameForTargetBlock: Block;
  modification?: string;
};

type ParametersForTestIdOfPage = {
  nameForTargetPage: Page;
  modification?: string;
};

type commonType = ParametersForTestIdOfElement &
  ParametersForTestIdOfBlock &
  ParametersForTestIdOfPage;
type keyType = keyof commonType;

export function getDataTestId(
  parameters: ParametersForTestIdOfElement | ParametersForTestIdOfBlock | ParametersForTestIdOfPage
): string {
  const parts = [];
  const keys: keyType[] = [
    'nameForTargetPage',
    'page',
    'nameForTargetBlock',
    'block',
    'nameForTargetElement'
  ];
  for (const key of keys) {
    //@ts-expect-error some keys doesn't exist in types included in the union type
    if (!!parameters[key] && parameters[key].length) {
      //@ts-expect-error some keys doesn't exist in types included in the union type
      parts.push(parameters[key]);
    }
  }

  return parameters?.modification
    ? `${parts.join('-')}--${parameters.modification}`
    : parts.join('-');
}
