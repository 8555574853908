import React, { InputHTMLAttributes } from 'react';
import { CheckIcon } from '@/shared/ui/icons';
import classNames from 'classnames';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
}

export const Checkbox: React.FC<Props> = ({
  checked,
  onChange,
  name,
  children,
  className,
  ...props
}) => {
  const checkbox = classNames(
    'flex h-2 w-2 min-w-2 min-w-[16px] items-center justify-center rounded-xxxs border',
    checked ? 'border-[#5E61654D]' : 'border-[#65666B]'
  );

  const container = classNames('flex cursor-pointer items-center', className);
  return (
    <label htmlFor={name} className={container}>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        className="hidden"
        onChange={onChange}
        {...props}
      />
      <div className={checkbox}>{checked && <CheckIcon className={'mt-1px'} />}</div>
      <span
        style={{
          wordBreak: 'break-word'
        }}
        className="ml-1 text-text-primary"
      >
        {children}
      </span>
    </label>
  );
};
