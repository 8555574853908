import { SvgIcon } from './svg-icon';
import { FC } from 'react';

type Props = {
  className?: string;
};

export const FiltersIcon: FC<Props> = ({ className }) => {
  return (
    <SvgIcon width={20} height={20} className={className}>
      <path
        d="M17.5 4.16669H2.5M2.5 10C2.5 10 13.5948 10 17.5 10M17.5 15.8334H2.5"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="M6.66667 5.83333C7.58714 5.83333 8.33333 5.08714 8.33333 4.16667C8.33333 3.24619 7.58714 2.5 6.66667 2.5C5.74619 2.5 5 3.24619 5 4.16667C5 5.08714 5.74619 5.83333 6.66667 5.83333Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="M14.1667 11.6666C15.0871 11.6666 15.8333 10.9205 15.8333 9.99998C15.8333 9.07951 15.0871 8.33331 14.1667 8.33331C13.2462 8.33331 12.5 9.07951 12.5 9.99998C12.5 10.9205 13.2462 11.6666 14.1667 11.6666Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="M5.83335 17.5C6.75383 17.5 7.50002 16.7538 7.50002 15.8334C7.50002 14.9129 6.75383 14.1667 5.83335 14.1667C4.91288 14.1667 4.16669 14.9129 4.16669 15.8334C4.16669 16.7538 4.91288 17.5 5.83335 17.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
