'use client';

import Image from 'next/image';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl: '/pink-devil-face.svg'
};
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' }
];

export const UserPanel: React.FC = () => {
  return (
    <Disclosure as="header" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl sm:px-4 lg:divide-gray-200 mx-auto px-2 lg:divide-y lg:px-8">
            <div className="h-16 relative flex justify-between">
              <div className="z-10 relative flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <Image
                    width={8}
                    height={8}
                    className="h-16 w-auto"
                    src="/round-black-devil-face.svg"
                    alt="Your Company"
                  />
                </div>
              </div>
              <div className="z-0 sm:absolute sm:inset-0 relative flex flex-1 items-center justify-center px-2">
                <div className="sm:max-w-xs w-full">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="text-gray-400 h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="py-1.5 text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6 block w-full rounded-md border-0 bg-white pl-10 pr-3 ring-1 ring-inset focus:ring-2 focus:ring-inset"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </div>
              </div>
              <div className="z-10 relative flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:ring-indigo-500 relative inline-flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-inset">
                  <span className="-inset-0.5 absolute" />
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="lg:z-10 hidden lg:relative lg:ml-4 lg:flex lg:items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500 focus:ring-indigo-500 relative flex-shrink-0 rounded-full bg-white p-1 focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <span className="-inset-1.5 absolute" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-4 flex-shrink-0">
                  <div>
                    <Menu.Button className="focus:ring-indigo-500 relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-offset-2">
                      <span className="-inset-1.5 absolute" />
                      <span className="sr-only">Open user menu</span>
                      <Image
                        width={8}
                        height={8}
                        className="h-8 w-8 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-10 w-48 absolute right-0 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'text-gray-700 block px-4 py-2 text-sm'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="border-gray-200 border-t pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <Image
                    width={8}
                    height={8}
                    className="h-10 w-10 rounded-full"
                    src={user.imageUrl}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-gray-800 text-base font-medium">{user.name}</div>
                  <div className="text-gray-500 text-sm font-medium">{user.email}</div>
                </div>
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500 focus:ring-indigo-500 relative ml-auto flex-shrink-0 rounded-full bg-white p-1 focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <span className="-inset-1.5 absolute" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className="text-gray-500 hover:bg-gray-50 hover:text-gray-900 block rounded-md px-3 py-2 text-base font-medium"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
